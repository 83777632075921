import { DsrFormElementTypes, translationsMapper } from './features/dsr/dsrForms/dsrFormsSlice'
import { ScanConditionsObjectTypes } from './interfaces'
import { LocalStorageKeys, localStorageService } from './utils/localstorageUtils'
import { upperSnakeCasetoTitleCase } from './utils/stringUtil'
import { i18n } from '@lightbeamai/design-system'
const en = require('./translations/en.json')
const fr = require('./translations/fr.json')
const es = require('./translations/es.json')
/**Hidden flag */
export const showPrivacyOps = true
export const showMergedEntities = true
export const showPlayBookFilterOnlyEntity = true
export const showRetainUntilField = false
export const showDsrFormsSearch = false
export const showCookieConsentCopyToClipboard = false
export const NO_WARNING_ON_DSR_DS_REMOVE = 'no-warning-on-dsr-ds-remove'

export const allowDrawCustomArea = false
/** Common */
export const EMPTY_CONTENT = '--'
export const ACCESSIBLE_BY = 'accessibleBy'
export const NEW = 'new'
/**RBAC roles */
export enum UserRoles {
  admin = 'Admin',
  readonly = 'View Only',
  dpo = 'Data Privacy Officer',
  dso = 'Datasource Owner',
  alertOwner = 'Alert Owner',
  collaborator = 'Collaborator',
  processOwner = 'Process Owner'
}
/**RBAC Actions */
export enum AccessLevelRoles {
  alertAssignment = 'Alert Assignment',
  alertResolution = 'Alert Resolution',
  annotation = 'Annotation',
  addAttribute = 'Attribute and Attribute Set Creation',
  createReport = 'Generate Reports',
  objectViewer = 'Object Viewer',
  addDataSource = 'Onboard Datasources',
  addRuleSet = 'Rule Set Creation',
  addNotification = 'Send Notification',
  addPartner = 'Add Partner',
  viewAttribute = 'View Attribute Instance'
}

/**RBAC Path */
export const ANNOTATION = 'Annotation'
/** API response headers */
export const TOTAL_COUNT_HEADER = 'x-total-items'
export const FILTER_COUNT_HEADER = 'x-filter-items'
export const SYNCED_FILES_HEADER = 'x-synced-files'
export const TOTAL_FILES_HEADER = 'x-total-files'

const apiUrl = process.env.INSTANCE_IP || ''

/** PAGE URLS */
export const URL_DAYZERO = '/first-landing'
export const URL_DASHBOARD = '/dashboard'
export const URL_LOGIN = `${apiUrl}/auth/realms/master/protocol/openid-connect/auth?client_id=lightbeam&response_type=code&redirect_uri=${window.location.origin}`
export const URL_SEARCH = '/search'
export const URL_DOCUMENT = '/document'
export const URL_FILES = '/files'
export const URL_DOCUMENTS_ALL = '/files'
export const URL_DOCUMENTS_CLASSIFIED = '/classified'
export const URL_DOCUMENTS_CLASSIFIED_CREATE = '/classified-create'
export const URL_DOCUMENTS_CLASSIFIED_SETTINGS = '/classified-settings'
export const URL_DOCUMENTS_CLASSIFIED_ANALYTICS = '/classified-analytics'
export const URL_DATA_SOURCE = '/data-source'
export const URL_DATA_SOURCES = '/data-sources'
export const URL_DATA_SOURCES_UNSTRUCTURED = '/data-sources-unstructured'
export const URL_DATA_SOURCES_STRUCTURED = '/data-sources-structured'
export const URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES = '/unclassified-with-entities'
export const URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES = '/unclassified-without-entities'
export const URL_TEMPLATES = '/templates'
export const URL_PROCESS = '/process'
export const URL_ATTRIBUTES = '/attributes'
export const URL_CUSTOM_ATTRIBUTES = '/custom-attributes'
export const URL_BLOB = '/blob'
export const URL_ATTRIBUTES_SET = '/attributes-set'
export const URL_ENTITY_ATTRIBUTES = '/entity-attributes'
export const URL_ENTITIES = '/entities'
export const URL_AUDIT_LOG = '/config-audit-log'
export const URL_TIME_MACHINE = '/time-machine'
export const URL_ENTITIES_DELETED = '/entities-deleted'
export const URL_PROJECTS = '/projects'
export const URL_TICKETS = '/tickets'
export const URL_REPORTERS = '/reporters'
export const URL_SHARES = '/shares'
export const URL_OVERVIEW = '/overview'
export const URL_PARTNER_ONBOARD = '/partner-onboard'
export const URL_BUCKETS = '/buckets'
export const URL_FOLDERS = '/folders'
export const URL_NO_SCAN = '/no-scan'
export const URL_LOCAL_ENTITIES = '/local-entities'
export const URL_MANAGE_ACCOUNT = '/manage-account'
export const URL_LABEL_MANAGEMENT = '/label-management'
export const URL_LABELS = '/labels'
export const URL_LABEL_SETS = '/label-sets'
export const URL_BLOCKS = '/blocks'

/* Temporary Constants Start */
export const URL_ATTRIBUTES_ENTITIES_CARD = '/attributes-entities-card'
export const URL_ATTRIBUTES_ENTITIES_LIST = '/attributes-entities-list'
/* Temporary Constants End */
export const URL_EMAILS = '/emails'
export const URL_ATTACHMENTS = '/attachments'
export const URL_RULES = '/rules'
export const URL_DATASOURCE_FORM = '/datasource-type-form'
export const URL_DATASOURCE_FORM_UNSTRUCTURED = '/datasource-unstructured'
export const URL_DATASOURCE_FORM_STRUCTURED = '/datasource-structured'
export const URL_REGISTER_ELASTIC_SEARCH = '/register-elastic-search'
export const URL_SETTINGS = '/settings'
export const URL_RESIDENCIES = '/residencies'
export const URL_LOCATIONS = '/locations'
export const URL_USERS = '/users'
export const URL_MEMBERS = '/members'
export const URL_EXTERNAL_MEMBERS = '/external-members'
export const URL_GROUPS_LIST = '/groups-list'
export const URL_GROUPS = '/groups'
export const URL_ROLES = '/roles'
export const URL_DRIVES = '/drives'
export const DRIVES = 'drives'
export const URL_ACTIVITIES = '/activities'
export const URL_SENTIMENT_ANALYSIS = '/sentiment-analysis'
export const URL_CONNECTED_APPS = '/connected-apps'
export const URL_MESSAGES = '/messages'
export const URL_CONVERSATIONS = '/conversations'
export const URL_VIOLATIONS = '/violations'
export const URL_DSR = '/dsr'
export const URL_AUTH_PROVIDERS = '/auth-providers'
export const URL_AUTH_PROVIDER = '/auth-provider'
export const URL_SMTP_CONFIG = '/config-smtp'
export const URL_REPORT_SETTING = '/config-report'
export const URL_USERS_MANAGEMENT = '/manage-users'
export const URL_INVITE_USER = '/invite-user'
export const URL_PRIVACY_OPS = '/privacy-ops'
export const URL_INSIGHTS = '/insights'
export const URL_PRIVACY_PARTNERS = '/privacy-partners'
export const URL_COOKIES_CONSENT = '/cookies-consent'
export const URL_CONSENT_MANAGEMENT = '/consent-management'
export const URL_DATASOURCE_DETAILS = '/datasource-details'
export const URL_PREFERENCE_CENTER = '/preference-center'
export const URL_PREFERENCE_CENTER_DETAILS = '/preference-center-details'
export const URL_AI_GOVERNANCE = '/ai-governance'
export const URL_DATA_SETS = '/data-sets'
export const URL_MODELS = '/models'
export const URL_ASSESSMENTS = '/assessments'
export const URL_RISKS = '/risks'
export const URL_PII = '/pii'
export const URL_SCOPE = '/scope'
export const URL_COOKIES = '/cookies'
export const URL_CONSENT_LOG = '/consent-log'
export const URL_SYSTEM_LOG = '/system-log'
export const URL_CONSENT_ALERTS = '/consent-alerts'
export const URL_CONSENT_SYSTEM_LOGS = '/system-logs'
export const URL_CONSENT_SETTINGS = '/consent-settings'
export const URL_CONSENT_CATEGORIES = '/consent-categories'
export const URL_CONSENT_PREFERENCE_PREVIEW = '/preference-preview'
export const URL_COOKIE_CATEGORIES = '/cookie-categories'
export const URL_SCAN_HISTORY = '/scan-history'
export const URL_BANNER = '/banner'
export const URL_ENTITY_PROFILE = '/profile'
export const URL_DATABASES = '/databases'
export const URL_SCHEMAS = '/schemas'
export const URL_TABLES = '/tables'
export const URL_COLUMNS = '/columns'
export const URL_REVIEW_CLUSTERS = '/review-clusters'
export const URL_TABLE_CLUSTERS = '/table-clusters'
export const URL_POLICY = '/policy'
export const URL_POLICIES = '/policies'
export const URL_DETAIL = '/detail'
export const URL_RULE_SET = '/rule-sets'
export const URL_PERMIT_LIST = '/permit-list'
export const URL_POLICY_SUMMARY = '/summary'
export const URL_POLICY_EXCEPTIONS = '/exceptions'
export const URL_POLICY_LIST = '/list'
export const URL_POLICY_NOTIFICATIONS_SETTINGS = '/policy-notifications-settings'
export const URL_POLICY_SETTINGS = '/policy-settings'
export const URL_POLICY_PARAMETERS = '/policy-parameters'
export const URL_ALERTS = '/alerts'
export const URL_ABOUT = '/about'
export const URL_NOTIFICATIONS = '/notifications'
export const URL_OBJECTS = '/objects'
export const URL_OBJECTS_IMPACTED = '/objects-impacted'
export const URL_OBJECTS_SHARED = '/objects-shared'
export const URL_EXTERNAL_USERS = '/external-users'
export const URL_FILTERS = '/filters'
export const URL_FILTER = '/filter'
export const URL_CREATE = '/create'
export const URL_EDIT = '/edit'
export const URL_REGISTER_GENERIC = '/generic-datasource'
export const URL_DSR_FORMS = '/forms'
export const URL_DSR_FORM_TEMPLATES = '/templates'
export const URL_DSR_FORM_BUILDER = '/constructor'
export const URL_PREVIEW = '/preview'
export const URL_FORM_DESIGNER = '/form-designer'
export const URL_DSR_REQUEST_MANAGEMENT = '/request-management'
export const URL_DSR_REQUEST_MANUAL = '/request-manual'
export const URL_DSR_REPORTS = '/dsr-reports'
export const URL_ROPA = '/ropa'
export const URL_PIA = '/pia'
export const URL_DEFINED_PROCESSES = '/defined-processes'
export const URL_DEFINE_PROCESS = '/define-process'
export const URL_COMPANIES = '/companies'
export const URL_PROCESS_GALLERY = '/process-gallery'
export const URL_REPORTS = '/reports'
export const URL_REQUEST = '/request'
export const URL_REQUESTS = '/requests'
export const URL_REQUEST_DATASOURCES = '/request-datasources'
export const URL_DEFINE_PROCESS_ACTIVITY = '/define-process-activity'
export const URL_CONFIGURE = '/configure'
export const URL_STEP_PROCESS_GALLERY = '/step-process-gallery'
export const URL_STEP_CONFIGURE_PROCESS = '/step-configure-process'
export const URL_STEP_QUESTIONNAIRE = '/step-questionnaire'
export const URL_STEP_CONFIGURE_PROCESS_GROUP = '/configure-process-group'
export const URL_STEP_CONFIGURE_PROCESS_COMPANY = '/configure-process-company'
export const URL_IN_PROGRESS = '/in-progress'
export const URL_COMPLETED = '/completed'
export const URL_DATA_MAP = '/data-map'
export const URL_OBJECT_VIEWER = '/object-viewer'
export const URL_PRINT_COVER = '/print-cover'
export const URL_PRINT_CLOSING = '/print-final'
export const URL_PRINT = '/print'
export const URL_DELTA_PRINT = '/delta-print'
export const URL_PRINT_ROPA_CONTENT = '/print-ropa'
export const URL_PRINT_PIA_CONTENT = '/print-pia'
export const URL_PRINT_DSR_CONTENT = '/print-dsr'
export const URL_PRINT_CC_VERSION_CONTENT = '/print-cc-version'
export const URL_EMAIL_TEMPLATES = '/email-templates'
export const URL_BANNERS = '/banners'
export const URL_DOMAINS = '/domains'
export const URL_DOMAIN_DETAILS = '/domain-details'
export const URL_ONDEMAND = '/ondemand'
export const URL_DATA_FLOW = '/data-flow'
export const URL_RISK_ASSESSMENT = '/risk'
export const URL_ONDEMAND_SCANNED_DOCUMENT = '/ondemand/scanned-document'
export const URL_DEPLOYMENT = '/deployment'
export const URL_ACCESS_CONTROL = '/access-control'
export const URL_RESOURCES = '/resources'
export const URL_CONFIGURATION = '/configuration'
export const URL_DIRECTORY_SERVICES = '/directory-services'
export const URL_SCAN_SETTINGS = '/scan-settings'
export const URL_OBJECTS_ACCESS = URL_ACCESS_CONTROL + '/objects-access'
export const URL_ACCESS_CONTROL_USERS = URL_ACCESS_CONTROL + URL_USERS
export const URL_ACCESS_CONTROL_GROUPS = URL_ACCESS_CONTROL + URL_GROUPS
export const URL_ACCESS_CONTROL_DASHBOARD = URL_ACCESS_CONTROL + URL_DASHBOARD
export const URL_SUB_GROUPS = '/sub-groups'
export const URL_PII_OBJECTS = '/pii-objects'
export const URL_ACCESS_CONTROL_OBJECTS = URL_ACCESS_CONTROL + URL_PII_OBJECTS
export const URL_DSR_WORKFLOW_AUTOMATION = '/workflow-automation'

export const URL_CLOUD_PLATFORMS = '/cloud-platforms'
export const URL_CLOUD_PLATFORMS_ONBOARDING = '/cloud-platforms-onboarding'
export const URL_CLOUD_RESOURCES = '/cloud-resources'
export const URL_AWS = '/aws'
export const URL_GCP = '/gcp'
export const URL_AZURE = '/azure'

export const URL_SITE_HELP = 'https://docs.lightbeam.ai/lxqobxw6ak7CTnsQjikH'
/** Filters */
export const FILTER_DANGER_KEY = 'danger'
export const FILTER_RISK_KEY = 'risky'
export const FILTER_RISK_LEVEL_KEY = 'riskLevel[]'
export const FILTER_RISK_TYPE_KEY = 'dataSet[]'
export const FILTER_LEGAL_HOLD_KEY = 'legalHold'
export const FILTER_DATASOURCE_CATEGORY = 'category'
export const FILTER_SECRET_CATEGORY = 'category'
export const FILTER_SENSITIVITY_MULTIPLE = 'sensitivity[]'
export const FILTER_COMMON_TYPE = 'commonType[]'
export const FILTER_SENSITIVITY = 'sensitivity'
export const FILTER_COLUMNS_STATUS = 'columnsStatus[]'
export const FILTER_CHANGE_TYPE = 'changeType[]'
export const FILTER_FROM_EMAIL_KEY = 'fromEmail'
export const FILTER_MAILBOX = 'MAILBOX'
export const FILTER_MAILBOX_KEY = 'MAILBOX[]'
export const FILTER_MAIL_FOLDER_TYPE = 'MAIL_FOLDER_TYPE'
export const FILTER_RECIPIENT_EMAIL_KEY = 'toEmail'
export const FILTER_MAIL_KEY = 'EMAIL'
export const FILTER_ATTRIBUTE_HAS_INSTANCES = 'hasInstances'
export const FILTER_ATTRIBUTE_NAME = 'attributeName[]'
export const FILTER_ATTRIBUTE_ID = 'attributeId[]'
export const FILTER_IDENTIFIER = 'identifier'
export const FILTER_NO_OF_DATA_SOURCES = 'noOfDataSources'
export const FILTER_ATTRIBUTE_SET = 'attributeSet[]'
export const FILTER_DATABASE = 'database[]'
export const FILTER_TABLE = 'table[]'
export const FILTER_SCHEMA = 'schema[]'
export const FILTER_PII = 'pii[]'
export const FILTER_REVIEW = 'reviewed[]'
export const FILTER_STRUCTURED_KEY = 'Structured'
export const FILTER_UNSTRUCTURED_KEY = 'Unstructured'
export const FILTER_PROJECT_IDS = 'projectId[]'
export const FILTER_PROJECT_ID = 'projectId[]'
export const FILTER_HAS_ATTACHMENT = 'hasAttachment'
export const FILTER_ASSIGNEE_EMAIL = 'assigneeEmail'
export const FILTER_REPORTER_EMAIL = 'reporterEmail'
export const FILTER_BLOB_PARENT = 'blobParent[]'
export const FILTER_DATASOURCES_IDS = 'datasourceIds[]'
export const FILTER_ORG_ROLE = 'orgRole[]'
export const FILTER_PROCESS_GROUP = 'processGroup[]'
export const FILTER_TEMPLATE_IDS = 'templateIds[]'
export const FILTER_ATTRIBUTE_DATASOURCEIDS_KEY = 'datasourceIds'
export const FILTER_POLICY_TYPES_KEY = 'policyTypes'
export const FILTER_RULESET_ID_KEY = 'ruleSetId'
export const FILTER_RETAIN_UNTIL = 'retainUntil'
export const FILTER_OWNER_KEY = 'owner[]'
export const FILTER_PROCESS_KEY = 'process[]'
export const FILTER_BUCKET_KEY = 'bucket[]'
export const FILTER_USER_IDS = 'userIds[]'
export const FILTER_PARTNER_ID_KEY = 'PARTNER_IDS'
export const API_FILTER_OWNER_KEY = 'OWNER'
export enum FILTER_DANGER_VALUES {
  isAtRisk = 'isAtRisk',
  isSensitive = 'isSensitive'
}
export enum FILTER_DANGER_API_VALUES {
  sensitive = 'Sensitive'
}
export const FILTER_TEMPLATE_KEY = 'templateName[]'
export const FILTER_DATA_SOURCE_KEY = 'datasource[]'
export const FILTER_FILE_TYPE_KEY = 'fileType[]'
export const FILTER_CLASS_KEY = 'class[]'
export const FILTER_LABEL_KEY = 'label[]'
export const FILTER_LAST_MODIFIED_TIME_KEY = 'LAST_MODIFIED_TIME'
export const FILTER_ACCESS_TYPE_KEY = 'accessType[]'
export const FILTER_STATUS_KEY = 'status[]'
export const FILTER_SUB_CLASS_KEY = 'subclass[]'
export const FILTER_ATTRIBUTEID_KEY = 'ATTRIBUTE_IDS'
export const FILTER_FOLDER_IDS = 'LB_FOLDER_IDS'
export const FILTER_ATTRIBUTE_KEY = 'attribute[]'
export const FILTER_DATA_SOURCE_ID_KEY = 'dataSourceId[]'
export const FILTER_RESIDENCY_KEY = 'residency[]'
export const FILTER_SEVERITY_KEY = 'severity[]'
export const FILTER_POLICY_TYPE = 'policyType[]'
export const FILTER_ALERT_STATUS = 'alertStatus[]'
export const FILTER_ALERT_KEY = 'HAS_ALERTS'
export const FILTER_RETENTION_VIOLATION = 'HAS_RETENTION_VIOLATION'
export const FILTER_SKIP_KEY = 'IS_SKIPPED'
export const FILTER_SKIP_REASON_KEY = 'SKIPPED_FILE_REASON[]'
export const FILTER_DRIVES_KEY = 'DRIVE_IDS[]'
export const FILTER_SHARED_KEY = 'SHARED_WITH[]'
export const FILTER_DATA_SOURCE_TYPE_KEY = 'datasourceType[]'
export const FILTER_RULE_SET_KEY = 'ruleSetIds[]'
export const FILTER_CLUSTER = 'clusterId'
export const FILTER_DATA_SOURCE_STATUS = 'datasourceStatus[]'
export const FILTER_DATA_SOURCE_NAME = 'datasourceName[]'
export const FILTER_DATA_SOURCE_OWNER = 'datasourceOwner[]'
export const FILTER_EMAIL_KEY = 'email[]'
export const FILTER_EVENT_TYPE = 'eventType[]'
export const FILTER_REQUEST_TYPE = 'requestType[]'
export const FILTER_ASSIGNEE = 'assigneeEmail[]'
export const FILTER_NEED_MANUAL_ACTION = 'needManualAction'
export const FILTER_TEMPLATE = 'template[]'
export const API_FILTER_RESIDENCIES_KEY = 'residencies'
export const PAGE_VIEW_KEY = 'pageView'
export const FILTER_MAIL_RECIPIENT = 'TO'
export const FILTER_ENTITY = 'entity[]'
export const FILTER_ATTRIBUTE_INSTANCE_IDS = 'attributeInstanceIds[]'
export const FILTER_ATTRIBUTE_INSTANCE_VALUE = 'attributeInstanceValue'
export const FILTER_DEPARTMENT = 'department'
export const FILTER_EMPLOYEE_TYPE = 'employeeType[]'
export const FILTER_GROUPS = 'groups[]'
export const RETURN_URL = 'returnUrl'
export const FILTER_REVIEW_STATUS = 'reviewStatus[]'
export const FILTER_REVIEW_SCHEDULE = 'reviewSchedule[]'
export const FILTER_RISK_LEVEL = 'reviewSchedule[]'
export const FILTER_CONSENT_EVENT = 'consentEvent[]'
export const FILTER_CONSENT_PURPOSE = 'consentPurpose[]'
export const FILTER_ENTITY_TYPE = 'entityType[]'
export const FILTER_LOCATION = 'location[]'
export const FILTER_ACCESS = 'access[]'
export const FILTER_USER_ID = 'userId'
export const FILTER_IS_OWNER = 'isOwner'
export const FILTER_HAS_ENTITY_ACCESS = 'hasEntityAccess'
export const FILTER_ACCESS_MODES = 'accessMode[]'
export const FILTER_OPEN_ADD_NEW_SUBSCRIPTION = 'openAddNewSubscription'

export const FILTER_DUE_DATE = 'dueDate'
export const FILTER_CREATED_DATE = 'createdAt'
export const FILTER_DUE_DATE_CUSTOM_KEY = 'custom_date_range'
export const FILTER_DATE_RANGE_KEY = 'dateRange'

export const FILTER_ATTRIBUTES_DETECTED = 'attributesDetected'
export const FILTER_ATTRIBUTES_VALIDATED = 'attributesValidated'
export const FILTER_VALIDATION_STATUS = 'validationStatus[]'

export const FILTER_ATTRIBUTE_REMARK = 'remark'
export const FILTER_ATTRIBUTE_ADD_TO_REPORT = 'addToReport'
export const FILTER_MEMBER_TYPE = 'memberType'

export const LABELS_KEY = 'DOCUMENT_LABEL_IDS'

export const FILTER_LAST_UPDATED = 'lastUpdated'
export const FILTER_FILE_TYPE = 'fileType[]'
export const FILTER_FILE_SIZE = 'fileSize'
export const FILTER_SITES = 'sites[]'
export const FILTER_SCAN_STATUS = 'scanStatus'

export const FILTER_ACTIONS_KEY = 'action[]'
export const FILTER_MODULES_TYPE = 'module[]'
export const FILTER_ATTRIBUTE_NAMES_KEY = 'attributeNames[]'

export const FILTER_CLOUD_PLATFORM_ID = 'cloudPlatformId'
export const FILTER_CLOUD_ACCOUNT_ID = 'cloudAccountId[]'
export const FILTER_REGION = 'region[]'

export const docAnalyticsScheduleType = 'HUMAN_READABLE_SCHEDULE'

export enum DOC_ANALYTICS_FILE_FILTERS {
  SCAN_STATUS = 'IS_SKIPPED',
  LAST_UPDATED = 'LAST_UPDATED_TIMESTAMP_CATEGORY',
  FILE_TYPE = 'EXTENSION',
  FILE_SIZE = 'FILE_SIZE_CATEGORY',
  SITES = 'SHAREPOINT_SITE_ID'
}

export enum DOC_ANALYTICS_FILES_REPORTS_FILTERS {
  SCAN_STATUS = 'skipped',
  LAST_UPDATED = 'lastUpdatedTimestampCategory',
  FILE_TYPE = 'extension',
  FILE_SIZE = 'fileSizeCategory',
  SITES = 'sharepointSiteID'
}
export const FILTER_OPT_STATUS = 'optStatus[]'
export const FILTER_ENTITY_IDENTIFIED = 'entityIdentified'
export const FILTER_COMMUNICATION_TYPE = 'communicationType[]'
export const FILTER_SYNC_STATUS = 'syncStatus'
export const FILTER_PREFERENCE_CENTER = 'preferenceCenter[]'
export const FILTER_PREFERENCE = 'preference[]'
export const FILTER_PREFERENCE_CATEGORY = 'category[]'
export const FILTER_PREFERENCE_CENTER_GROUP = 'preferenceCenterGroup[]'

export enum FILTER_ATTRIBUTES_DETECTED_VALUES {
  yes = 'Yes',
  no = 'No'
}

export enum FILTER_ATTRIBUTES_VALIDATED_VALUES {
  yes = 'Yes',
  no = 'No'
}

export enum FILTER_VALIDATION_STATUS_VALUES {
  NOT_STARTED = 'notStarted',
  TICKET_ASSIGNED = 'ticketAssigned',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed'
}

export enum FILTER_ATTRIBUTE_REMARK_VALUES {
  yes = 'Yes',
  no = 'No'
}

export enum FILTER_ATTRIBUTE_ADD_TO_REPORT_VALUES {
  yes = 'Yes',
  no = 'No'
}

export enum FILTER_PII_VALUES {
  pii = 'pii',
  maybePii = 'maybePii',
  nonPii = 'nonPii'
}

export enum FILTER_COMMON_TYPE_VALUES {
  string = 'STRING',
  number = 'NUMBER',
  blob = 'BLOB',
  boolean = 'BOOLEAN',
  timestamp = 'TIMESTAMP'
}

export enum PAGE_VIEW_VALUES {
  list = 'list',
  card = 'card'
}
export enum FILTER_SENSITIVITY_VALUES {
  low = 'Low',
  medium = 'Medium',
  high = 'High'
}
export const NAVTAB_BUCKETS = 'buckets'
export enum DSR_REQUEST_STATUS_FILTER_TYPES {
  unverified = 'unverified',
  inProgress = 'inProgress',
  closed = 'closed',
  all = 'all'
}
export enum DSR_REQUEST_STEP {
  ID_VERIFICATION = 'id-verification',
  ID_VERIFIED = 'id-verified',
  DATA_DISCOVERY = 'data-discovery',
  REPORT = 'report',
  CLOSURE = 'closure',
  COMMUNICATION = 'communication'
}
export enum DSR_REQUEST_DATA_DISCOVERY_TABS {
  validatedData = 'validated-data',
  entity360 = 'entity-360',
  dataSources = 'data-sources'
}
export enum DSR_TICKET_STATUS_FILTER_TYPES {
  open = 'open',
  closed = 'closed'
}

export enum DSR_TICKET_EVENT_TYPES {
  sendReminder = 'SEND_REMINDER',
  revokeAssignment = 'REVOKE_ASSIGNMENT',
  reassignTicket = 'REASSIGN_TICKET',
  sendNewTicket = 'SEND_NEW_TICKET',
  rejectTicket = 'REJECT_TICKET'
}
export const FILTER_DSR_REQUEST_ASSIGNEE = 'assignee[]'
export const FILTER_DSR_REQUEST_TYPE = 'type[]'
export const FILTER_DSR_REQUEST_TEMPLATE = 'template[]'
export const FILTER_DSR_REQUEST_STEP = 'step'

/** Left Nav Tab items */
export const NAVTAB_EMAILS = 'emails'
export const NAVTAB_PARTNER_ONBOARD = 'partner-onboard'
export const NAVTAB_ENTITIES = 'entities'
export const NAVTAB_CLASSIFICATIONS = 'classifications'
export const NAVTAB_DOCUMENTS_CLASSIFIED = 'classified'
export const NAVTAB_DOCUMENTS_ALL = 'files'
export const NAVTAB_TIME_MACHINE = 'time-machine'
export const NAVTAB_SHARES = 'shares'
export const NAVTAB_TEMPLATES = 'templates'
export const NAVTAB_ATTRIBUTES = 'attributes'
export const NAVTAB_PROJECTS = 'projects'
export const NAVTAB_ATTRIBUTES_SET = 'attributes-set'
export const NAVTAB_LOGO = 'logo'
export const NAVTAB_SUMMARY = 'summary'
export const NAVTAB_SETTINGS = 'settings'
export const NAVTAB_DSR = 'dsr'
export const NAVTAB_COOKIES_CONSENT = 'cookies-consent'
export const NAVTAB_DRIVES = 'drives'
export const NAVTAB_CONVERSATION = 'conversation'
export const NAVTAB_OBJECTS = 'objects'
export const NAVTAB_AUDIT_TRAIL = 'auditTrail'
export const NAVTAB_PROFILE = 'profile'
export const NAVTAB_POLICIES_SUMMARY = 'summary'
export const NAVTAB_POLICIES_LIST = 'list'
export const NAVTAB_PERMIT_LIST = 'permit-list'
export const NAVTAB_POLICIES_EXCEPTIONS = 'exceptions'
export const NAVTAB_POLICIES_BASIC_INFO = 'Basic Information'
export const NAVTAB_POLICIES_SETTINGS = 'Policy Settings'
export const NAVTAB_ALERTS = 'alerts'
export const NAVTAB_USERS = 'users'
export const NAVTAB_MEMBERS = 'members'
export const NAVTAB_GROUPS = 'groups'
export const NAVTAB_TICKETS = 'tickets'
export const NAVTAB_REPORTERS = 'reporters'
export const NAVTAB_NO_SCAN = 'no-scan'
export const NAVTAB_AUTH_PROVIDERS = 'auth-providers'
export const NAVTAB_USERS_MANAGEMENT = 'manage-users'
export const NAVTAB_SMTP_CONFIG = 'config-smtp'
export const NAVTAB_LABEL_MANAGEMENT = 'label-management'
export const NAVTAB_REPORT_CONFIG = 'config-report'
export const NAVTAB_AUDIT_LOG_CONFIG = 'config-audit-log'
export const NAVTAB_ABOUT = 'about'
export const NAVTAB_OBJECTS_ACCESS = 'objects-access'
export const NAVTAB_ACCESS_DASHBOARD = 'access-dashboard'
export const NAVTAB_USERS_ACCESS = 'users-access'
export const NAVTAB_GROUPS_ACCESS = 'groups-access'
export const NAVTAB_ACCESS_CONTROL = 'access-control'

/** URL query params */
export const REFERRER = 'referrer'
export const FROM = 'from'
export const AUTH = 'auth'
export const LIMIT_DEFAULT = 50
export const POLICY_DATASOURCES_LIMIT_DEFAULT = 999
export const DATASOURCES_LIMIT_DEFAULT = 500
export const GRAPHQL_LIMIT_DEFAULT = 500
export const AUDIT_LOG_OBJECTS_LIMIT_DEFAULT = 10
export const DSR_DATASOURCES_LIMIT_DEFAULT = 10
export const PAGE = 'page'
export const PAGE_SIZE = 'pageSize'
export const PAGE_SIZES = [50, 100, 150, 200]
export const COUNT = 'count'
export const LIMIT = 'limit'
export const OFFSET = 'offset'
export const SEARCH_QUERY = 'searchQuery'
export const DATA_SOURCE_TYPE = 'datasourceType'
export const SENDER = 'sender'
export const SEVERITY = 'severity'
export const META_URL = 'metaUrl'
export const PREVIEW = 'preview'
export const SETTINGS = 'settings'
export const DOCUMENT_ID = 'documentId'
export const CONVERSATION_DOCUMENT_ID = 'conversationDocId'
export const POLICY_TYPE = 'policyType'
export const ANNOTATION_TYPE = 'annotationType'
export const MESSAGE = 'message'
export const TEXT = 'text'
export const DATA_SOURCE_ID = 'datasourceId'
export const USER_ID = 'userId'
export const GROUP_ID = 'groupId'
export const LB_FOLDER_ID = 'folderId'
export const ASSIGNEE = 'assignee'
export const DRIVE_ID = 'driveId'
export const TICKET_ID = 'ticketId'
export const IS_AT_RISK = 'isAtRisk'
export const IS_ON_LEGAL_HOLD = 'isOnLegalHold'
export const LAST_MODIFIED_TIME_PARAM = 'lastModifiedTime'
export const IS_SENSITIVE = 'isSensitive'
export const IS_OPEN = 'isOpen'
export const IS_CLOSED = 'isClosed'
export const IS_IN_PROGRESS = 'isInProgress'
export const IS_ALL = 'all'
export const IS_DRAFT = 'isDraft'
export const IS_UNRESOLVED = 'isUnresolved'
export const IS_RESOLVED = 'isResolved'
export const IS_FORMS = 'isForms'
export const IS_TEMPLATES = 'isTemplates'
export const IS_REQUESTS = 'isRequests'
export const ACTIVE_TAB = 'activeTab'
export const REOPEN = 'reopen'
export const ACTIVE_STEP = 'step'
export const ACTIVE_OBJECT_VIEWER_TAB = 'activeObjectViewerTab'
export const SORT_DIRECTION = 'sortDir'
export const SORT_BY = 'sortBy'
export const START_DATE = 'startDate'
export const END_DATE = 'endDate'
export const DATABASE_ID = 'databaseId'
export const CLUSTER_ID = 'clusterId'
export const TABLE_ID = 'tableId'
export const COLUMN_ID = 'columnId'
export const ATTRIBUTE_ID = 'attributeId'
export const SCHEMA_ID = 'schemaId'
export const ALERT_ID = 'alertId'
export const PROJECT_ID = 'projectId'
export const ENTITY_ID = 'userEntityId'
export const BLOB_ID = 'blobId'
export const FILTER_TYPE = 'type'
export const FILTER_CHANNEL_NAME = 'channelName'
export const FILTER_CHANNEL_ID = 'channelId'
export const FILTER_CHANNEL_TYPE = 'channelType'
export const FILTER_TEAM_ID = 'teamId[]'
export const FILTER_MODULE_TYPE = 'module'
export const API_PARAM_CLASS = 'class'
export const API_PARAM_ACCESS_TYPE = 'accessType'
export const API_PARAM_SUB_CLASS = 'subclass'
export const API_PARAM_TEMPLATE_NAME = 'templateName'
export const API_PARAM_DATA_SOURCE_TYPE = 'datasourceType'
export const API_PARAM_FILE_TYPE = 'type'
export const API_PARAM_ATTRIBUTE = 'attribute'
export const API_PARAM_PROJECT_ID = 'projectId'
export const QUERY_KEY_POLICY = 'policy'
export const BUCKET_ID = 'bucketId'
export const SOURCE = 'source'
export const VIEW = 'view'
export const REPORT_ID = 'reportId'
export const ROPA_ID = 'ropaId'
export const SHARE_ID = 'shareId'
export const EDIT = 'edit'
export const DOCUMENT_LABEL_PROVIDER = 'documentLabelProvider'
export const TRIGGER_ENTITY_SCAN = 'triggerEntityScan'
export const DATABASE_SCAN_TYPE = 'databaseScanType'
export const ACTION = 'action'
export const REQUEST_TYPE = 'requestType'
export const WORK_FLOW_ID = 'workflowId'
export const EVENT_TYPE = 'eventType'
export const TEMPLATE_ID = 'templateId'
export const USER_NAME = 'name'
export const CLOUD_PLATFORM = 'cloudPlatform'
export const CLOUD_PLATFORM_PARAM = 'cloudPlatformType'
export const CLOUD_RESOURCE_PARAM = 'cloudResourceType'
export const CLOUD_DS_FLAG = 'isCloudBasedDs'
export const CLOUD_PLATFORM_ID = 'cloudPlatformId'
export const CLOUD_RESOURCE_ID_PARAM = 'cloudResourceId'
export const ACTION_HISTORY_ALERT_ID = 'actionHistoryAlertId'

/** Action names */
export const ACTION_BREADCRUMBS_GET = 'breadcrumbs/get'
export const ACTION_USER_INFO = 'auth/userInfo'
export const ACTION_AUTH_LOGIN = 'auth/login'
export const ACTION_TEXT_LABELS_GET = 'document/identifiers/fetchTextLabels'

/** Auth */
export const accessTokenKey = 'x-access-token'

/** Data Sources */
export const MAX_RESULTS_PER_PAGE = 10
export enum DATASOURCE_CONNECTION {
  success = 'success',
  failed = 'failed',
  inProgress = 'inProgress'
}

export enum DATASOURCE_CREATE_OR_UPDATE_STATUS {
  created = 'created',
  createFailed = 'createFailed',
  createInProgress = 'createInProgress',
  updated = 'updated',
  updateFailed = 'updateFailed',
  updateInProgress = 'updateInProgress'
}

export enum DATASOURCE_STATUS {
  good = 'Good',
  scanning = 'Scanning',
  scanned = 'Scanned',
  disconnected = 'Disconnected',
  registering = 'Registering',
  deleting = 'Deleting',
  paused = 'Paused',
  pausing = 'Pausing',
  resuming = 'Resuming'
}

export const DASHBOARD_ENTITY_URL = 'entities'
export const DASHBOARD_PRIVACY_OPS_URL = 'privacy-ops'
export const DASHBOARD_INSIGHTS_URL = 'insights'
export const DASHBOARD_PRIVACY_PARTNERS_URL = 'partners'
export const DASHBOARD_SETTINGS_URL = 'settings'
export const DASHBOARD_ACCESS_CONTROL_URL = 'access-control'

export const AWS_REGION_OPTIONS_MAP = {
  'US East (N. Virginia) us-east-1': 'us-east-1',
  'US East (Ohio) us-east-2': 'us-east-2',
  'US West (N. California) us-west-1': 'us-west-1',
  'US West (Oregon) us-west-2': 'us-west-2',
  'Africa (Cape Town) af-south-1': 'af-south-1',
  'Asia Pacific (Hong Kong) ap-east-1': 'ap-east-1',
  'Asia Pacific (Jakarta) ap-southeast-3': 'ap-southeast-3',
  'Asia Pacific (Mumbai) ap-south-1': 'ap-south-1',
  'Asia Pacific (Osaka) ap-northeast-3': 'ap-northeast-3',
  'Asia Pacific (Seoul) ap-northeast-2': 'ap-northeast-2',
  'Asia Pacific (Singapore) ap-southeast-1': 'ap-southeast-1',
  'Asia Pacific (Sydney) ap-southeast-2': 'ap-southeast-2',
  'Asia Pacific (Tokyo) ap-northeast-1': 'ap-northeast-1',
  'Canada (Central) ca-central-1': 'ca-central-1',
  'Europe (Frankfurt) eu-central-1': 'eu-central-1',
  'Europe (Ireland) eu-west-1': 'eu-west-1',
  'Europe (London) eu-west-2': 'eu-west-2',
  'Europe (Milan) eu-south-1': 'eu-south-1',
  'Europe (Paris) eu-west-3': 'eu-west-3',
  'Europe (Stockholm) eu-north-1': 'eu-north-1',
  'Middle East (Bahrain) me-south-1': 'me-south-1',
  'Middle East (UAE) me-central-1': 'me-central-1',
  'South America (São Paulo) sa-east-1': 'sa-east-1'
}

export enum DATA_SOURCE_TYPES {
  uploadDoc = 'UPLOAD_DOC',
  generic = 'GENERIC',
  remote = 'REMOTE',
  gdrive = 'GOOGLE_DRIVE',
  salesForce = 'SALESFORCE',
  aws = 'AWS_S3',
  googleCloudStorage = 'GOOGLE_CLOUD_STORAGE',
  slack = 'SLACK',
  gmail = 'GMAIL',
  elasticSearch = 'ELASTICSEARCH',
  oneDrive = 'ONE_DRIVE',
  box = 'BOX',
  snowflake = 'SNOWFLAKE',
  oracle = 'ORACLE',
  sharePoint = 'SHAREPOINT',
  outLook = 'OUTLOOK',
  exchange = 'EXCHANGE',
  mssql = 'MSSQL',
  mySql = 'MySQL',
  postgreSql = 'POSTGRESQL',
  redshift = 'REDSHIFT',
  glue = 'GLUE',
  databricks = 'DATABRICKS',
  jira = 'JIRA',
  zenDeskSupport = 'ZENDESK_SUPPORT',
  hubspot = 'HUBSPOT',
  adp = 'ADP',
  gdrivePersonal = 'gdrivePersonal',
  mixedDatasource = 'MIXEDDS',
  innovaccer = 'INNOVACCER',
  serviceNow = 'SERVICE_NOW',
  sap = 'SAP',
  smb = 'SMB',
  bigQuery = 'BIGQUERY',
  looker = 'LOOKER',
  azureBlob = 'AZURE_BLOB',
  iterable = 'ITERABLE',
  teams = 'MS_TEAMS',
  marketo = 'MARKETO',
  dynamoDb = 'DYNAMODB',
  mongoDb = 'MONGODB',
  dropbox = 'DROPBOX',
  cosmosPostgres = 'COSMOS_POSTGRESQL',
  cosmosMongoDb = 'COSMOS_MONGODB',
  cosmosNoSql = 'COSMOS_NOSQL',
  confluence = 'CONFLUENCE',
  rds = 'RDS',
  auroraPostgres = 'AURORA_POSTGRESQL',
  auroraMySql = 'AURORA_MYSQL',
  fsx = 'FSX'
}

export enum DS_FLAVOURS {
  cosmos = 'COSMOS',
  aurora = 'AURORA'
}

/** This maps datasource type to API parameter required to onboard a datasource*/
export const DATA_SOURCE_TYPE_API_MAP = {
  [DATA_SOURCE_TYPES.uploadDoc]: 'Upload a document',
  [DATA_SOURCE_TYPES.salesForce]: 'Salesforce',
  [DATA_SOURCE_TYPES.gdrive]: 'Google Drive',
  [DATA_SOURCE_TYPES.gmail]: 'Gmail',
  [DATA_SOURCE_TYPES.aws]: 'AWS S3',
  [DATA_SOURCE_TYPES.googleCloudStorage]: 'Google Cloud Storage',
  [DATA_SOURCE_TYPES.azureBlob]: 'Azure Blob',
  [DATA_SOURCE_TYPES.slack]: 'Slack',
  [DATA_SOURCE_TYPES.teams]: 'Microsoft Teams',
  [DATA_SOURCE_TYPES.oracle]: 'Oracle',
  [DATA_SOURCE_TYPES.snowflake]: 'Snowflake',
  [DATA_SOURCE_TYPES.mssql]: 'MsSQL',
  [DATA_SOURCE_TYPES.mySql]: 'MySQL',
  [DATA_SOURCE_TYPES.postgreSql]: 'PostgreSQL',
  [DATA_SOURCE_TYPES.redshift]: 'Redshift',
  [DATA_SOURCE_TYPES.glue]: 'Glue',
  [DATA_SOURCE_TYPES.databricks]: 'Databricks',
  [DATA_SOURCE_TYPES.oneDrive]: 'OneDrive',
  [DATA_SOURCE_TYPES.box]: 'Box',
  [DATA_SOURCE_TYPES.outLook]: 'Outlook',
  [DATA_SOURCE_TYPES.elasticSearch]: 'Elasticsearch',
  [DATA_SOURCE_TYPES.sharePoint]: 'Sharepoint',
  [DATA_SOURCE_TYPES.jira]: 'Jira',
  [DATA_SOURCE_TYPES.zenDeskSupport]: 'ZendeskSupport',
  [DATA_SOURCE_TYPES.generic]: 'Generic',
  [DATA_SOURCE_TYPES.hubspot]: 'HubSpot',
  [DATA_SOURCE_TYPES.adp]: 'ADP',
  [DATA_SOURCE_TYPES.innovaccer]: 'Innovaccer',
  [DATA_SOURCE_TYPES.serviceNow]: 'ServiceNow',
  [DATA_SOURCE_TYPES.sap]: 'SAP',
  [DATA_SOURCE_TYPES.remote]: 'Remote',
  [DATA_SOURCE_TYPES.exchange]: DATA_SOURCE_TYPES.exchange,
  [DATA_SOURCE_TYPES.smb]: DATA_SOURCE_TYPES.smb,
  [DATA_SOURCE_TYPES.bigQuery]: 'BigQuery',
  [DATA_SOURCE_TYPES.looker]: 'Looker',
  [DATA_SOURCE_TYPES.iterable]: 'Iterable',
  [DATA_SOURCE_TYPES.marketo]: 'Marketo',
  [DATA_SOURCE_TYPES.dynamoDb]: 'DynamoDB',
  [DATA_SOURCE_TYPES.mongoDb]: 'MongoDB',
  [DATA_SOURCE_TYPES.dropbox]: 'Dropbox',
  [DATA_SOURCE_TYPES.cosmosPostgres]: 'PostgreSQL',
  [DATA_SOURCE_TYPES.cosmosMongoDb]: 'MongoDB',
  [DATA_SOURCE_TYPES.cosmosNoSql]: 'Cosmos_NoSQL',
  [DATA_SOURCE_TYPES.confluence]: 'Confluence',
  [DATA_SOURCE_TYPES.rds]: 'RDS',
  [DATA_SOURCE_TYPES.auroraPostgres]: 'PostgreSQL',
  [DATA_SOURCE_TYPES.auroraMySql]: 'MySQL',
  [DATA_SOURCE_TYPES.fsx]: 'FSx'
}

export const mapDataSourceApiTypesToStandard = (type?: string): DATA_SOURCE_TYPES => {
  if (type === 'Salesforce') return DATA_SOURCE_TYPES.salesForce
  if (type === 'Confluence') return DATA_SOURCE_TYPES.confluence
  if (type === 'Marketo') return DATA_SOURCE_TYPES.marketo
  if (type === 'Google Drive') return DATA_SOURCE_TYPES.gdrive
  if (type === 'Gmail') return DATA_SOURCE_TYPES.gmail
  if (type === 'AWS S3') return DATA_SOURCE_TYPES.aws
  if (type === 'Google Cloud Storage') return DATA_SOURCE_TYPES.googleCloudStorage
  if (type === 'Azure Blob') return DATA_SOURCE_TYPES.azureBlob
  if (type === 'Slack') return DATA_SOURCE_TYPES.slack
  if (type === 'Teams') return DATA_SOURCE_TYPES.teams
  if (type === 'Oracle') return DATA_SOURCE_TYPES.oracle
  if (type === 'Snowflake') return DATA_SOURCE_TYPES.snowflake
  if (type === 'MsSQL') return DATA_SOURCE_TYPES.mssql
  if (type === 'MySQL') return DATA_SOURCE_TYPES.mySql
  if (type === 'PostgreSQL') return DATA_SOURCE_TYPES.postgreSql
  if (type === 'OneDrive') return DATA_SOURCE_TYPES.oneDrive
  if (type === 'Box') return DATA_SOURCE_TYPES.box
  if (type === 'Dropbox') return DATA_SOURCE_TYPES.dropbox
  if (type === 'Outlook') return DATA_SOURCE_TYPES.outLook
  if (type === 'Elasticsearch') return DATA_SOURCE_TYPES.elasticSearch
  if (type === 'Sharepoint') return DATA_SOURCE_TYPES.sharePoint
  if (type === 'Jira') return DATA_SOURCE_TYPES.jira
  if (type === 'ZendeskSupport') return DATA_SOURCE_TYPES.zenDeskSupport
  if (type === 'Hubspot') return DATA_SOURCE_TYPES.hubspot
  if (type === 'ADP') return DATA_SOURCE_TYPES.adp
  if (type === 'Innovaccer') return DATA_SOURCE_TYPES.innovaccer
  if (type === 'ServiceNow') return DATA_SOURCE_TYPES.serviceNow
  if (type === 'SMB') return DATA_SOURCE_TYPES.smb
  if (type === 'SAP') return DATA_SOURCE_TYPES.sap
  if (type === 'Remote') return DATA_SOURCE_TYPES.remote
  if (type === 'Exchange') return DATA_SOURCE_TYPES.exchange
  if (type === 'Zendesk Support') return DATA_SOURCE_TYPES.zenDeskSupport
  if (type === 'BigQuery') return DATA_SOURCE_TYPES.bigQuery
  if (type === 'Looker') return DATA_SOURCE_TYPES.looker
  if (type === 'Iterable') return DATA_SOURCE_TYPES.iterable
  if (type === 'Redshift') return DATA_SOURCE_TYPES.redshift
  if (type === 'Glue') return DATA_SOURCE_TYPES.glue
  if (type === 'DynamoDB') return DATA_SOURCE_TYPES.dynamoDb

  return DATA_SOURCE_TYPES.generic
}

export enum RemoteDataSourceTypes {
  hive = 'Hive',
  elastic = 'Elastic',
  splunk = 'Splunk',
  dataDog = 'DataDog',
  mongo = 'MongoDB',
  dropBox = 'Dropbox',
  box = 'Box',
  hubSpot = 'Hubspot',
  adp = 'ADP',
  freshWorks = 'Freshworks',
  marketo = 'Marketo',
  confluence = 'Confluence',
  neo4j = 'Neo4j',
  kafka = 'Kafka',
  adyen = 'Adyen',
  affirm = 'Affirm',
  afterPlay = 'Afterpay',
  afterShip = 'Aftership',
  applePay = 'Apple Pay',
  asana = 'Asana',
  authorizeNet = 'Authorize.net',
  awsAurora = 'AWS Aurora',
  bugsNag = 'Bugsnag',
  cyberSource = 'Cybersource',
  easyPost = 'Easypost',
  gcpBigQuery = 'GCP BigQuery',
  googleWorkspace = 'Google Workspace',
  klarna = 'Klarna',
  looker = 'Looker',
  mailGun = 'Mailgun',
  nmi = 'NMI',
  pagerDuty = 'Pagerduty',
  pendo = 'Pendo',
  radial = 'Radial',
  Stripe = 'Stripe',
  twilio = 'Twilio',
  vantivWorldpay = 'Vantiv / Worldpay',
  whitePagesEkata = 'Whitepages / Ekata',
  azureTableStorage = 'Azure Table Storage',
  azureDataExplorer = 'Azure Data Explorer',
  freshDesk = 'Freshdesk',
  freshSales = 'Freshsales',
  freshService = 'Freshservice',
  gitLab = 'GitLab',
  gitHub = 'GitHub',
  notion = 'Notion',
  outreach = 'Outreach',
  plaid = 'Plaid',
  sugarCrm = 'Sugar CRM',
  mailChimp = 'Mailchimp',
  sap = 'SAP',
  iterable = 'Iterable',
  others = 'Others'
}

export const GSUITE_CONFIG = 'gsuiteConfiguration'

/** Image Filenames */
export const IMAGE_FILENAME_CCPA = 'ccpa.png'
export const IMAGE_FILENAME_GDPR = 'gdpr.png'
export const IMAGE_FILENAME_HIPAA = 'hipaa.png'
/** Document */
export const DOC_TYPE_IMAGE = 'image'
export const DOC_TYPE_PDF = 'pdf'
export const DOC_TYPE_TEXT = 'text'
export const DOC_TYPE_TXT = 'txt'
export const DOC_TYPE_CSV = 'csv'
export const DOC_TYPE_XLS = 'xls'
export const DOC_TYPE_XLSX = 'xlsx'
export const DOC_TYPE_DOC = 'doc'
export const DOC_TYPE_DOCX = 'docx'
export const DOC_TYPE_LOG = 'log'
export const DOC_TYPE_UNDEFINED = 'undefined'

export enum OBJECT_TYPES {
  file = 'FILE',
  message = 'MESSAGE',
  blob = 'BLOB'
}

export enum MAIL_FOLDER_TYPE {
  Sent = 'SENT',
  Inbox = 'INBOX'
}

export enum ACTION_TYPE {
  edit = 'edit',
  create = 'create',
  delete = 'delete',
  update = 'update',
  clone = 'clone',
  createTemplate = 'createTemplate',
  updateTemplate = 'updateTemplate'
}

export enum STATUS_ALERT_ACIVITY {
  closed = 'in progress',
  inProgress = 'pending',
  open = 'open'
}

export enum SHARED_ENTITY_TYPE {
  external = 'External',
  internal = 'Internal'
}

export enum RISK_LEVELS {
  high = 'high',
  medium = 'medium',
  low = 'low',
  noRisk = 'noRisk'
}

export enum TEAMS_MAPPER {
  private = 'Private',
  public = 'Public',
  org = 'Org-Wide'
}

export const RADIAN = Math.PI / 180

export const DATEFORMAT = 'YYYY-MM-DD'
export const US_DATEFORMAT = 'MM-DD-YYYY'
export const TIMEFORMAT = 'HH.MM A'

export const BREADCRUMB_ITEM_MAX_CHARS = 15

export const POLICY_PLAYBOOKS_OPTIONS = ['Delete cross border data']
export const POLICY_PROFILES_OPTIONS = ['UK customers: data outside border']
export const POLICY_OBJECT_MATCH_CRITERIA_OPTIONS = ['Based on profile-map']
export const POLICY_OBJECT_MAP_FILE_OPTIONS = ['sample-json-data-file']
export const POLICY_DATASOURCE_INCLUDE_OPTIONS = ['G-Drive folders contains  PII']
export const POLICY_PROFILE_AVAILABILITY_OPTIONS = [
  'Only customers',
  'Only employees',
  'Only vendors',
  'All'
]

export enum OBJECT_REVOKE_ACCESS_TYPES {
  all = 'REVOKE_ALL_ACCESS',
  allWrite = 'REVOKE_ALL_WRITE_ACCESS',
  external = 'REVOKE_EXTERNAL_ACCESS',
  externalWrite = 'REVOKE_EXTERNAL_WRITE_ACCESS',
  link = 'REVOKE_LINK_ACCESS'
}

export const RevokeAccessOptions = [
  OBJECT_REVOKE_ACCESS_TYPES.all,
  OBJECT_REVOKE_ACCESS_TYPES.allWrite,
  OBJECT_REVOKE_ACCESS_TYPES.external,
  OBJECT_REVOKE_ACCESS_TYPES.externalWrite,
  OBJECT_REVOKE_ACCESS_TYPES.link
]

export const POLICY_FILTER_TYPES = [
  ...(!showPlayBookFilterOnlyEntity
    ? [
        {
          id: '1',
          name: 'Data source',
          filter: 'DATA_SOURCE_FILTERS',
          translationKey: 'filter.name.datasource'
        }
      ]
    : []),
  ...(!showPlayBookFilterOnlyEntity
    ? [
        {
          id: '2',
          name: 'Attribute set',
          filter: 'ATTRIBUTE_SET_FILTERS',
          translationKey: 'filter.name.attributeset'
        }
      ]
    : []),
  ...(!showPlayBookFilterOnlyEntity
    ? [{ id: '3', name: 'Label', filter: 'LABEL_FILTERS', translationKey: 'filter.name.label' }]
    : []),
  {
    id: '4',
    name: 'Entity Profile',
    filter: 'PROFILE_FILTERS',
    translationKey: 'filter.name.entity'
  },
  ...(!showPlayBookFilterOnlyEntity
    ? [
        {
          id: '5',
          name: 'Timebased',
          filter: 'TIME_BASED_FILTERS',
          translationKey: 'filter.name.timebased'
        }
      ]
    : []),
  ...(!showPlayBookFilterOnlyEntity
    ? [{ id: '6', name: 'User', filter: 'USER_FILTERS', translationKey: 'filter.name.user' }]
    : []),
  ...(!showPlayBookFilterOnlyEntity
    ? [{ id: '7', name: 'Group', filter: 'GROUP_FILTERS', translationKey: 'filter.name.group' }]
    : []),
  ...(!showPlayBookFilterOnlyEntity
    ? [
        {
          id: '8',
          name: 'Regulation',
          filter: 'REGULATION_FILTERS',
          translationKey: 'filter.name.regulation'
        }
      ]
    : [])
]

export enum FILTER_TYPES {
  toggle = 'toggle',
  discreet = 'discreet',
  grouped = 'grouped'
}

// Temporary enum to add date range to the filter types
export enum FILTER_TYPES_V2 {
  toggle = 'toggle',
  discreet = 'discreet',
  grouped = 'grouped',
  dateRange = 'date-range'
}

export const DEFAULT_FILTER_TYPE_ID_SELECTED = '4'

export enum PLAYBOOK_SUBPAGES {
  alerts = 'alerts',
  filters = 'filters',
  rulsesets = 'rule-sets',
  create = 'create',
  policy = 'policy',
  policyDetail = 'detail',
  notifications = 'notifications',
  notificationsSettings = 'policy-notifications-settings',
  policySettings = 'policy-settings'
}

export enum ClassificationSubPages {
  List = 'classified',
  Analytics = 'classified-analytics',
  Create = 'classified-create',
  Detail = 'classified-detail',
  Settings = 'classified-settings'
}

export enum MANAGE_ACCOUNT_SUBPAGES {
  language = 'Language'
}

export enum ALERT_FILTERS {
  resolved = 'Resolved',
  unresolved = 'Unresolved',
  all = 'All'
}

export enum ALERT_ACTIONS {
  resolve = 'RESOLVE',
  reassign = 'REASSIGN',
  mute = 'MUTE',
  unMute = 'UNMUTE',
  permit = 'PERMIT',
  noScan = 'NO_SCAN',
  sendReminder = 'SEND_REMINDER',
  deleteObject = 'DELETE_OBJECT',
  revokeAccess = 'REVOKE_ACCESS',
  deleteFromSource = 'DELETE_FROM_SOURCE',
  legalHold = 'LEGAL_HOLD',
  archive = 'ARCHIVE_OBJECT_DATASOURCE',
  extendRetention = 'EXTEND_RETENTION'
}

export enum ALERT_MUTE_DURATION_TYPE {
  day = 'DAY',
  week = 'WEEK'
}

export enum ALERT_EXTEND_DURATION_TYPE {
  month = 'MONTH',
  year = 'YEAR'
}

export enum SEVERITY_TYPE {
  info = 'info',
  critical = 'critical',
  warning = 'warning'
}

export enum FILTER_FILE_IMPORT_OPTIONS {
  'file' = 'File',
  'webURL' = 'Web URL'
}

export const FIQLOperatorsMapper = {
  '==': 'equals',
  '!=': 'not equals',
  ',': 'OR',
  ';': 'AND'
}

export enum FILTER_ATTRIBUTE {
  'column' = 'column',
  'condition' = 'condition',
  'value' = 'value',
  'combinator' = 'combinator'
}

export enum CONDITION_COMBINATOR {
  OR = 'OR',
  AND = 'AND'
}

export const FILTER_CONDITIONS = [
  'equals',
  'not equals',
  'contains',
  'not contains',
  'starts with',
  'greater than'
]

export enum FILTER_CONDITION {
  'equals' = 'equals',
  'not equals' = 'not equals'
}

export enum POLICY_TYPES {
  'DATA_SHARING_POLICY' = 'DATA_SHARING_POLICY',
  'ENTITY_LOCATION_POLICY' = 'ENTITY_LOCATION_POLICY',
  'CROSS_BORDER_DATA_POLICY' = 'CROSS_BORDER_DATA_POLICY',
  'INFORMATION_MANAGEMENT_POLICY' = 'INFORMATION_MANAGEMENT_POLICY',
  'RETENTION' = 'RETENTION',
  'DATA_DUPLICATION_POLICY' = 'DATA_DUPLICATION_POLICY',
  'DATA_CONSENT_POLICY' = 'DATA_CONSENT_POLICY',
  'DOCUMENT_LABELING_POLICY' = 'DOCUMENT_LABELING_POLICY',
  'DATA_LIFECYCLE_POLICY' = 'DATA_LIFECYCLE_POLICY',
  'ACCESS_POLICY' = 'ACCESS_POLICY'
}

export enum POLICY_CLASSIFICATIONS {
  ACCESS_CONTROL = 'ACCESS_CONTROL',
  CLIENT_AI = 'CLIENT_AI',
  'INFORMATION_EXPOSURE' = 'INFORMATION_EXPOSURE',
  'DATA_LIFECYCLE' = 'DATA_LIFECYCLE'
}

export enum POLICY_TYPES_MAPPER {
  'DATA_SHARING_POLICY' = 'policy.externalUsers',
  'INFORMATION_MANAGEMENT_POLICY' = 'policy.internalUsers',
  'ENTITY_LOCATION_POLICY' = 'policy.infoLeakage',
  'CROSS_BORDER_DATA_POLICY' = 'policy.crossBorder',
  'RETENTION' = 'policy.retention',
  'DATA_DUPLICATION_POLICY' = 'policy.duplication',
  'DATA_CONSENT_POLICY' = 'policy.consentManagement',
  'DOCUMENT_LABELING_POLICY' = 'policy.labeling',
  'DATA_LIFECYCLE_POLICY' = 'policy.dataLifecycle',
  'ACCESS_POLICY' = 'policy.accessPolicy'
}
export enum POLICY_TYPES_MAPPER_COMPACT {
  'DATA_SHARING_POLICY' = 'policy.informationExposure',
  'ENTITY_LOCATION_POLICY' = 'policy.clientAi',
  'CROSS_BORDER_DATA_POLICY' = 'policy.clientAi',
  'INFORMATION_MANAGEMENT_POLICY' = 'policy.informationExposure',
  'RETENTION' = 'policy.dataLifecycle',
  'DATA_DUPLICATION_POLICY' = 'policy.dataLifecycle',
  'DOCUMENT_LABELING_POLICY' = 'policy.dataLifecycle',
  'DATA_CONSENT_POLICY' = 'policy.clientAi',
  'DATA_LIFECYCLE_POLICY' = 'policy.dataLifecycle',
  'ACCESS_POLICY' = 'policy.accessControl'
}
export enum POLICY_TYPES_PREFIX_MAPPER {
  'INFORMATION_EXPOSURE' = 'policy.informationExposure',
  'CLIENT_AI' = 'policy.clientAi',
  'DATA_LIFECYCLE' = 'policy.dataLifecycle',
  'ACCESS_CONTROL' = 'policy.accessControl'
}

export const POLICY_TYPE_ENTITY_LOACATION = 'ENTITY_LOCATION_POLICY'
export const POLICY_TYPE_DATA_SHARING = 'DATA_SHARING_POLICY'
export const INFORMATION_MANAGEMENT_POLICY = 'INFORMATION_MANAGEMENT_POLICY'
export enum FILTER_TYPE_OPTION {
  'PROFILE_FILTERS' = 'PROFILE_FILTERS'
}

export enum ACCESS_CONTROL_TYPES {
  OPEN_ACCESS = 'OPEN_ACCESS',
  EXCESSIVE_ACCESS = 'EXCESSIVE_ACCESS'
}

export enum OPEN_ACCESS_TYPES {
  EXTERNAL_PEOPLE = 'EXTERNAL_PEOPLE',
  EXTERNAL_PEOPLE_WITH_WRITE_ACCESS = 'EXTERNAL_PEOPLE_WITH_WRITE_ACCESS',
  ANYONE_WITH_THE_LINK = 'ANYONE_WITH_THE_LINK',
  ANYONE_WITH_THE_LINK_HAS_WRITE_ACCESS = 'ANYONE_WITH_THE_LINK_HAS_WRITE_ACCESS',
  ORGANIZATION_WITH_THE_LINK = 'ORGANIZATION_WITH_THE_LINK',
  ORGANIZATION_WITH_THE_LINK_HAS_WRITE_ACCESS = 'ORGANIZATION_WITH_THE_LINK_HAS_WRITE_ACCESS'
}

export enum EXCESSIVE_ACCESS_TYPES {
  READ_WRITE = 'EXCESSIVE_ACCESS',
  WRITE_ACCESS = 'EXCESSIVE_ACCESS_WITH_WRITE_ACCESS'
}

export enum SEVERITY_LEVEL {
  info = 'INFO',
  warning = 'WARNING',
  critical = 'CRITICAL'
}

export enum REGULATIONS {
  GDPR = 'GDPR',
  CCPA = 'CCPA',
  HIPAA = 'HIPAA'
}

export enum RETENTION_ATTRIBUTE_TYPES {
  LAST_MODIFIED_AT = 'LAST_MODIFIED_AT',
  CREATED_AT = 'CREATED_AT',
  METADATA_ID = 'METADATA_ID'
}

export const SYSTEM_RETENTION_ATTRIBUTE_TYPES = [
  RETENTION_ATTRIBUTE_TYPES.CREATED_AT,
  RETENTION_ATTRIBUTE_TYPES.LAST_MODIFIED_AT
]

export const DURATIONS = [
  { key: 'hour', value: 'HOURS', text: 'Hour(s) ago' },
  { key: 'day', value: 'DAYS', text: 'Day(s) ago' },
  { key: 'week', value: 'WEEKS', text: 'Week(s) ago' },
  { key: 'month', value: 'MONTHS', text: 'Month(s) ago' },
  { key: 'year', value: 'YEARS', text: 'Year(s) ago' }
]

export const FREQUENCY_DURATIONS = [
  { key: 'month', value: 'MONTH', label: 'Month(s)' },
  { key: 'year', value: 'YEAR', label: 'Year(s)' }
]

export enum SCAN_FREQUENCY_RUN_ON_PRESETS {
  lastDay = 'LAST_DAY'
}

export const SCAN_FREQUENCY_RUN_ON_DAYS = [...Array.from({ length: 31 }, (_, i) => i + 1)].map(
  (value) => ({
    key: value,
    value: value,
    label: `Day ${value}`
  })
)

export const SCAN_FREQUENCY_RUN_ON_DURATIONS = [
  ...SCAN_FREQUENCY_RUN_ON_DAYS,
  ...Object.values(SCAN_FREQUENCY_RUN_ON_PRESETS).map((value) => ({
    key: value,
    value: value,
    label: upperSnakeCasetoTitleCase(value)
  }))
]

export enum ALERT_ASSIGMENT_OPTIONS {
  DATASOURCE_OWNER = 'DATASOURCE_OWNER',
  OBJECT_OWNER = 'OBJECT_OWNER'
}

export const ALERT_ASSIGNEE_TYPES = {
  ...ALERT_ASSIGMENT_OPTIONS,
  others: 'OTHERS'
} as const

export type AlertAssigneeType = typeof ALERT_ASSIGNEE_TYPES[keyof typeof ALERT_ASSIGNEE_TYPES]

export const POLICY_REGULATIONS = [REGULATIONS.CCPA, REGULATIONS.GDPR, REGULATIONS.HIPAA]

export const mapSeverityLevelToText = {
  [SEVERITY_LEVEL.info]: 'severity.info',
  [SEVERITY_LEVEL.warning]: 'severity.warning',
  [SEVERITY_LEVEL.critical]: 'severity.critical'
}

export const POLICY_ID = 'policyId'
export const GROUP_BY = 'groupBy'
export const FILTER_BY = 'filterBy'
export const POLICY = 'policy'
export const POLICY_TYPE_ID = 'policyTypeId'
export const CLASSIFICATION_ID = 'classificationId'
export const ID = 'id'

export enum CLASSICATION_TYPE {
  IDENTITY = 'Identity',
  FINANCIAL = 'Financial',
  MEDICAL = 'Medical',
  HUMAN_RESOURCE = 'Human Resource',
  LEGAL = 'Legal',
  SOCIAL = 'Social',
  FINANCIAL_DOCUMENT = 'Financial Document',
  GOVT_ID = 'Government Id',
  ATTENTION_NEEDED = 'Attention Needed',
  OTHERS = 'Others',
  UNCLASSIFIED = 'Unclassified',
  EXPORT_CONTROLLED = 'Export Controlled',
  MISC = 'Misc - sensitive',
  BUSINESS_OPERATIONS = 'Business Operations',
  CUSTOM = 'Custom'
}

/** GraphQL **/
export enum GRAPHQL_CLASSIFICATION_TYPE {
  PII = 'PII',
  NOT_PII = 'NOT_PII',
  IDENTITY = 'IDENTITY',
  ENTITY_TYPE = 'ENTITY_TYPE'
}

export const OWNER_TYPE_SYSTEM = 'system'
export const OWNER_TYPE_USER = 'user'

export enum ATTRIBUTE_SET_TYPES {
  PERSONAL = 'PERSONAL',
  FINANCIAL = 'FINANCIAL',
  NATIONAL = 'NATIONAL',
  MEDICAL = 'MEDICAL',
  LEGAL = 'LEGAL',
  OTHERS = 'OTHERS',
  CUSTOM = 'CUSTOM'
}
export enum DATASOURCE_CATEGORY {
  ALL = 'All',
  EMAIL = 'Messaging',
  CRM = 'CRM & Dev Tools',
  FILES = 'File Repositories',
  DATA_LAKES = 'Databases & Datalakes',
  REMOTE = 'Remote Data Source',
  SCAN = 'Scan files and folder',
  COSMOS = 'Azure Cosmos DB',
  CLOUD_PLATFORMS = 'Cloud Platform'
}

export const DATASOURCE_CATEGORIES_COUNT_DEFAULT = 5

export const DATASOURCE_PURPOSE_OPTIONS = ['Used for personal validation']
export enum DataSourceProcessingStages {
  collection = 'COLLECTION',
  processing = 'PROCESSING',
  storage = 'STORAGE',
  exchange = 'EXCHANGE_OR_TRANSFER',
  archival = 'ARCHIVAL'
}

export enum DropboxOnboardingMethod {
  direct = 'CREDENTIALS',
  oauth = 'OAUTH'
}

export enum DataSourceLocations {
  global = 'Global',
  usa = 'USA',
  europe = 'Europe',
  uk = 'UK',
  apac = 'APAC',
  africa = 'Africa',
  southAmerica = 'South America',
  northAmerica = 'North America',
  others = 'Others'
}
export enum hubspotCredentialsTitle {
  private = 'Private App Credentials'
}
/** Data Source form constants */

export const DATASOURCE_FORM_NAME = 'name'
export const DATASOURCE_FORM_DELEGATED_CREDENTIAL = 'delegatedCredential'
export const DATASOURCE_FORM_CLIENT_ID = 'clientId'
export const DATASOURCE_FORM_CLIENT_SECRECT = 'clientSecret'
export const DATASOURCE_FORM_ACCOUNT_TYPE = 'accountType'
export const DATASOURCE_FORM_TENANT_ID = 'tenantId'
export const DATASOURCE_FORM_EVENT_HUB_NAME = 'eventHubName'
export const DATASOURCE_FORM_VAULT_URL = 'vaultURL'
export const DATASOURCE_FORM_SECRET_NAME = 'secretName'
export const DATASOURCE_FORM_ENTERPRISE_ID = 'enterpriseId'
export const DATASOURCE_FORM_OWNERS = 'createdBy'
export const DATASOURCE_FORM_URL = 'url'
export const DATASOURCE_FORM_REFRESH_TOKEN = 'refreshToken'
export const DATASOURCE_FORM_USERNAME = 'username'
export const DATASOURCE_FORM_PASSWORD = 'password'
export const DATASOURCE_FORM_DOMAIN = 'domain'
export const DATASOURCE_FORM_SMB_URL = 'smbUrl'
export const DATASOURCE_FOLDER_ID = 'folderId'
export const DATASOURCE_SRC_ID = 'srcDatasourceId'
export const DATASOURCE_FORM_APP_KEY = 'appKey'
export const DATASOURCE_FORM_APP_SECRET = 'appSecret'
export const DATASOURCE_FORM_TEAM_ADMIN_EMAIL = 'teamAdminEmail'

export const MAP_ATTRIBUTE_TOP_CLASS_TO_COLORS = ['#7457F7', '#AC8CFF', '#009DFF']
export const MAP_ATTRIBUTE_OTHER_CLASS_TO_COLORS = ['#41B6C4', '#A1DAB4', '#FFDE03']
export const MAP_ATTRIBUTE_TOP_CLASS_TO_OLD_COLORS = ['#AFC9E3', '#3E80C6', '#41B6C4']
export const MAP_ATTRIBUTE_OTHER_CLASS_TO_OLD_COLORS = ['#41B6C4', '#A1DAB4', '#FFDE03']

export const ATTRIBUTES_SET_NAME_ALL = 'All Attributes (Set + Non Set)'
export enum CHANNEL_TYPES {
  public = 'public',
  private = 'private',
  standard = 'standard',
  shared = 'shared'
}

export enum ATTCHEMENT_TYPE {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLSX = 'xlsx',
  XLS = 'xls',
  PPTX = 'pptx',
  PNG = 'png',
  CSV = 'csv',
  JPG = 'jpg',
  JPEG = 'jpeg',
  TXT = 'text',
  OTHER = 'other',
  PLAINTXT = 'txt'
}
export enum API_FILTER_ALERTS {
  NAME = 'NAME', // Name of the alert instance.
  POLICY_INSTANCE = 'POLICY_INSTANCE', // Name of the policy instance.
  POLICY_TYPE = 'POLICY_TYPE', // Name of the policy type.
  STATUS = 'STATUS', // Alert status.
  SEVERITY = 'SEVERITY', // Alert severity.
  DATA_SOURCE_TYPE = 'DATASOURCE_TYPE', // Alert severity.,
  PARTNER_ID_KEY = 'PARTNER_IDS'
}

export enum API_FILTER_DATA_SOURCES {
  name = 'NAME',
  createdBy = 'CREATED_BY',
  location = 'LOCATION',
  classification = 'CLASSIFICATION',
  subclass = 'SUB_CLASSIFICATION',
  templateName = 'TEMPLATE_NAME',
  isSensitive = 'IS_SENSITIVE',
  isRisky = 'IS_RISKY',
  status = 'STATUS',
  type = 'TYPE',
  attributeIds = 'ATTRIBUTE_IDS',
  datasourceType = 'DATASOURCE_TYPE',
  isOnLegalHold = 'IS_ON_LEGAL_HOLD',
  includeStructDataRelatedTableData = 'INCLUDE_STRUCT_DATA_RELATED_TABLE_DATA',
  lastModifiedTime = 'LAST_MODIFIED_TIME'
}

/** GraphQl maps */
export enum ALERT_STATUS {
  active = 'ACTIVE_STATUS',
  inactive = 'INACTIVE_STATUS',
  resolved = 'RESOLVED_STATUS',
  ignored = 'IGNORED_STATUS',
  resolving = 'RESOLVING_STATUS',
  muting = 'MUTING_STATUS',
  unmuting = 'UNMUTING_STATUS'
}

export enum AlertStatuses {
  active = 'Active',
  inactive = 'Inactive',
  resolved = 'Resolved',
  ignored = 'Ignored'
}

export const alertIntermediateStatuses = [
  ALERT_STATUS.resolving,
  ALERT_STATUS.muting,
  ALERT_STATUS.unmuting
]

export const mapAlertStatusToText = {
  [ALERT_STATUS.active]: 'alertStatus.active',
  [ALERT_STATUS.inactive]: 'alertStatus.inactive',
  [ALERT_STATUS.resolved]: 'alertStatus.resolved',
  [ALERT_STATUS.ignored]: 'alertStatus.ignored'
}

export const ALERT_STATUS_API_MAP = {
  [ALERT_STATUS.active]: 'Active',
  [ALERT_STATUS.inactive]: 'Inactive',
  [ALERT_STATUS.resolved]: 'Resolved',
  [ALERT_STATUS.ignored]: 'Ignored',
  [ALERT_STATUS.resolving]: 'Resolving',
  [ALERT_STATUS.muting]: 'Muting',
  [ALERT_STATUS.unmuting]: 'Unmuting'
}

export enum AUTOMATION_ACTIONS {
  REDACTION = 'REDACTION'
}
export const DATASOURCE_AUTOMATION_ACTIONS = {
  [DATA_SOURCE_TYPES.zenDeskSupport]: [AUTOMATION_ACTIONS.REDACTION],
  [DATA_SOURCE_TYPES.salesForce]: [AUTOMATION_ACTIONS.REDACTION]
}

export enum SENSITIVE_LABEL {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export const SENSITIVE_LABEL_API_MAP = {
  [SENSITIVE_LABEL.LOW]: 'low',
  [SENSITIVE_LABEL.MEDIUM]: 'medium',
  [SENSITIVE_LABEL.HIGH]: 'high'
}

export const SEVERITY_API_MAP = {
  [SEVERITY_LEVEL.info]: 'Info',
  [SEVERITY_LEVEL.warning]: 'Warning',
  [SEVERITY_LEVEL.critical]: 'Critical'
}

export const TEMPLATE1_DATASOURCES = [
  DATA_SOURCE_TYPES.gdrive,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.dropbox,
  DATA_SOURCE_TYPES.box
]
export const MAIL_DATASOURCES = [DATA_SOURCE_TYPES.gmail, DATA_SOURCE_TYPES.outLook]
export const TEMPLATE2_DATASOURCES = [
  DATA_SOURCE_TYPES.slack,
  DATA_SOURCE_TYPES.teams,
  ...MAIL_DATASOURCES,
  DATA_SOURCE_TYPES.exchange
]

export const STRUCTURED_DATASOURCES = [
  DATA_SOURCE_TYPES.snowflake,
  DATA_SOURCE_TYPES.mssql,
  DATA_SOURCE_TYPES.mySql,
  DATA_SOURCE_TYPES.postgreSql,
  DATA_SOURCE_TYPES.redshift,
  DATA_SOURCE_TYPES.glue,
  DATA_SOURCE_TYPES.databricks,
  DATA_SOURCE_TYPES.oracle,
  DATA_SOURCE_TYPES.bigQuery,
  DATA_SOURCE_TYPES.looker,
  DATA_SOURCE_TYPES.dynamoDb,
  DATA_SOURCE_TYPES.mongoDb,
  DATA_SOURCE_TYPES.cosmosPostgres,
  DATA_SOURCE_TYPES.cosmosMongoDb,
  DATA_SOURCE_TYPES.cosmosNoSql,
  DATA_SOURCE_TYPES.auroraPostgres,
  DATA_SOURCE_TYPES.auroraMySql
]

export const MIXED_DATASOURCES = [
  DATA_SOURCE_TYPES.hubspot,
  DATA_SOURCE_TYPES.adp,
  DATA_SOURCE_TYPES.zenDeskSupport,
  DATA_SOURCE_TYPES.innovaccer,
  DATA_SOURCE_TYPES.serviceNow,
  DATA_SOURCE_TYPES.salesForce,
  DATA_SOURCE_TYPES.confluence,
  DATA_SOURCE_TYPES.iterable,
  DATA_SOURCE_TYPES.marketo
]
export enum DataSourcesStructuredConfigConstants {
  mssql = 'MsSQLConfig',
  mysql = 'MySQLConfig',
  snowflake = 'SnowflakeDatasourceConfig',
  postgresql = 'PostgreSQLConfig',
  oracle = 'OracleDatasourceConfig',
  zendDeskSupport = 'ZendeskSupportConfig',
  innovaccer = 'InnovaccerConfig',
  serviceNow = 'ServiceNowConfig',
  remote = 'RemoteDataSourceConfig',
  offline = 'OfflineSchemaUploadConfig',
  bigQuery = 'BigQueryConfig',
  looker = 'lookerConfig',
  redshift = 'RedshiftConfig',
  cosmosNoSql = 'CosmosNoSQLConfig'
}

export enum DataSourcesConfigurations {
  mssql = 'msSqlConfiguration',
  mysql = 'mySqlConfiguration',
  snowflake = 'snowflakeConfiguration',
  postgresql = 'postgreSqlConfiguration',
  redshift = 'redshiftConfiguration',
  glue = 'glueDatasourceConfiguration',
  databricks = 'databricksDatasourceConfiguration',
  oracle = 'oracleConfiguration',
  zenDeskSupport = 'zendeskSupportConfiguration',
  innovaccer = 'innovaccerConfiguration',
  serviceNow = 'serviceNowConfiguration',
  remote = 'remoteDatasourceConfiguration',
  offline = 'offlineSchemaUploadConfiguration',
  hubspot = 'hubSpotConfiguration',
  salesForce = 'salesforceConfiguration',
  confluence = 'confluenceConfiguration',
  bigQuery = 'bigqueryConfiguration',
  looker = 'lookerConfiguration',
  adp = 'adpConfiguration',
  iterable = 'iterableConfiguration',
  marketo = 'marketoConfiguration',
  mongoDb = 'mongoDBDatasourceConfiguration',
  dynamoDb = 'dynamoDBDatasourceConfiguration',
  dropbox = 'dropboxConfiguration',
  cosmosNoSql = 'cosmosNoSQLDatasourceConfiguration'
}
export const mapDsStructuredTypeNameToConfigName = {
  [DataSourcesStructuredConfigConstants.mssql]: DataSourcesConfigurations.mssql,
  [DataSourcesStructuredConfigConstants.mysql]: DataSourcesConfigurations.mysql,
  [DataSourcesStructuredConfigConstants.snowflake]: DataSourcesConfigurations.snowflake,
  [DataSourcesStructuredConfigConstants.postgresql]: DataSourcesConfigurations.postgresql,
  [DataSourcesStructuredConfigConstants.oracle]: DataSourcesConfigurations.oracle,
  [DataSourcesStructuredConfigConstants.zendDeskSupport]: DataSourcesConfigurations.zenDeskSupport,
  [DataSourcesStructuredConfigConstants.innovaccer]: DataSourcesConfigurations.innovaccer,
  [DataSourcesStructuredConfigConstants.serviceNow]: DataSourcesConfigurations.serviceNow,
  [DataSourcesStructuredConfigConstants.offline]: DataSourcesConfigurations.offline,
  [DataSourcesStructuredConfigConstants.bigQuery]: DataSourcesConfigurations.bigQuery,
  [DataSourcesStructuredConfigConstants.looker]: DataSourcesConfigurations.looker,
  [DataSourcesStructuredConfigConstants.redshift]: DataSourcesConfigurations.redshift,
  [DataSourcesStructuredConfigConstants.cosmosNoSql]: DataSourcesConfigurations.cosmosNoSql
}

export const ATTRIBUTE_IDS = 'ATTRIBUTE_IDS'
export const ATTRIBUTE_SET_IDS = 'ATTRIBUTE_SET_IDS'
export const ENTITY_IDS = 'ENTITY_IDS'
export const ATTRIBUTE_SENSITIVITY = 'ATTRIBUTE_SENSITIVITY'
export const SCHEMA = 'SCHEMA'
export const DATABASE = 'DATABASE'
export const CHANGE_EVENT_TYPE = 'CHANGE_EVENT_TYPE'
export const CLASSIFICATION = 'CLASSIFICATION'
export const CATEGORY = 'CATEGORY'
export const INCIDENT_ID = 'INCIDENT_ID'
export const TEMPLATE_IDS = 'TEMPLATE_IDS'
export const GRAPHQL_API_FILTERS = {
  databaseId: 'DATABASE_ID',
  databaseIds: 'DATABASE_IDS',
  severity: 'SEVERITY',
  isSensitive: 'IS_SENSITIVE',
  commonType: 'COMMON_TYPE',
  isRisky: 'IS_RISKY',
  isReviewed: 'IS_REVIEWED',
  isMaybePII: 'IS_MAYBE_PII',
  isPiiOrMaybePii: 'IS_PII_OR_MAYBE_PII',
  includeDraftChanges: 'INCLUDE_DRAFT_CHANGES',
  parentTableId: 'PARENT_TABLE_ID',
  isCategory: 'IS_CATEGORY',
  dataSourceIds: 'DATASOURCE_IDS',
  entityIds: 'ENTITY_IDS',
  isNoScan: 'IS_NO_SCAN_ITEM',
  enabled: 'ENABLED',
  attributeIds: ATTRIBUTE_IDS,
  attributeSetIds: ATTRIBUTE_SET_IDS,
  attributeSensitivity: ATTRIBUTE_SENSITIVITY,
  schemas: SCHEMA,
  databases: DATABASE,
  category: CATEGORY,
  incidentId: INCIDENT_ID,
  messageId: 'MESSAGE_ID',
  documentId: 'DOCUMENT_ID',
  requestType: 'REQUEST_TYPE',
  eventType: 'EVENT_TYPE',
  tableId: 'TABLE_ID',
  changeEventType: CHANGE_EVENT_TYPE,
  classification: CLASSIFICATION,
  templateIds: TEMPLATE_IDS,
  alertIds: 'ALERT_IDS',
  hasRetentionViolation: 'HAS_RETENTION_VIOLATION',
  hasDeleteViolation: 'HAS_DELETE_VIOLATION',
  dueDate: 'DUE_DATE',
  createdAt: 'CREATED_AT',
  requestCreatedAt: 'REQUEST_CREATED_AT',
  assigneeEmail: 'ASSIGNEE_EMAIL',
  manualAction: 'MANUAL_ACTION',
  assignee: 'ASSIGNEE',
  optStatus: 'OPT_STATUS',
  entityType: 'ENTITY_TYPE',
  entityIdentified: 'ENTITY_IDENTIFIED',
  communicationType: 'COMMUNICATION_TYPE',
  location: 'LOCATION',
  syncStatus: 'SYNC_STATUS',
  preferenceCenters: 'PREFERENCE_CENTER',
  preference: 'PREFERENCE',
  preferenceCategory: 'PREFERENCE_CATEGORY',
  preferenceCenterGroup: 'PREFERENCE_CENTER_GROUP',
  preferenceCenter: 'PREFERENCE_CENTER',
  createdBy: 'CREATED_BY'
}

export const REPORTS_API_PARAMETER_KEYS = {
  datasourceId: 'DATASOURCE_ID',
  entityId: 'ENTITY_ID',
  alertId: 'ALERT_ID',
  includeStructTableRelatedData: 'INCLUDE_STRUCT_DATA_RELATED_TABLE_DATA'
}

/** Clusters */
export const ORPHAN_CLUSTER_ID = 'Orphan Tables'
export const ALL_CLUSTERS = 'All Clusters'
export const ORPHAN_CLUSTER_TYPE_NAME = 'OrphanTableCluster'
export const CLUSTER_TYPE_NAME = 'AnchorTableCluster'

export enum EntityTypes {
  supplier = 'Supplier',
  customer = 'Customer'
}

export enum ConstraintTypes {
  primaryKey = 'PRIMARY_KEY',
  foreignKey = 'FOREIGN_KEY',
  referenceTimestamp = 'REFERENCE_TIMESTAMP',
  entityIdentifier = 'ENTITY_IDENTIFIER',
  markAsPartOfEntity = 'PART_OF_ENTITY'
}

export const GENERIC_EMAIL_PARTNERS = 'genericEmailPartners'
export const GENERIC_EMAIL_PARTNERS_IDS = 'genericEmailPartnersIds'
export const EXTERNAL_EMAIL_GROUP_TEXT = 'External Emails'

export const MYSQL_DEFAULT_PORT = '3306'
export const POSTGRESQL_DEFAULT_PORT = '5432'
export const REDSHIFT_DEFAULT_PORT = '5439'

export const DAY_MS = 86400000
export const DATASOURCE_VALUES_KEYS = {
  [DATA_SOURCE_TYPES.salesForce]: 'salesforceConfiguration',
  [DATA_SOURCE_TYPES.confluence]: 'confluenceConfiguration',
  [DATA_SOURCE_TYPES.aws]: 'awsS3Configuration',
  [DATA_SOURCE_TYPES.googleCloudStorage]: 'googleCloudStorageConfiguration',
  [DATA_SOURCE_TYPES.azureBlob]: 'azureBlobConfiguration',
  [DATA_SOURCE_TYPES.outLook]: 'microsoftOfficeConfig',
  [DATA_SOURCE_TYPES.slack]: 'slackConfiguration',
  [DATA_SOURCE_TYPES.gmail]: 'gsuiteConfiguration',
  [DATA_SOURCE_TYPES.gdrive]: 'gsuiteConfiguration',
  [DATA_SOURCE_TYPES.elasticSearch]: 'elasticsearchConfiguration',
  [DATA_SOURCE_TYPES.oneDrive]: 'microsoftOfficeConfig',
  [DATA_SOURCE_TYPES.box]: 'boxConfiguration',
  [DATA_SOURCE_TYPES.sharePoint]: 'microsoftOfficeConfig',
  [DATA_SOURCE_TYPES.teams]: 'microsoftOfficeConfig',
  [DATA_SOURCE_TYPES.jira]: 'jiraConfiguration',
  [DATA_SOURCE_TYPES.smb]: 'smbConfiguration',
  [DATA_SOURCE_TYPES.marketo]: 'marketoConfiguration',
  [DATA_SOURCE_TYPES.mongoDb]: 'mongoDBDatasourceConfiguration',
  [DATA_SOURCE_TYPES.dropbox]: 'dropboxConfiguration'
}

export enum POLICY_FORM_STEPS {
  POLICY_FORM_STEP_1 = 1,
  POLICY_FORM_STEP_2,
  POLICY_FORM_STEP_3,
  POLICY_FORM_STEP_4
}

export enum POLICY_CREATE_ALERT_STATUS {
  ENABLE = '1',
  DISABLE = '0'
}

export enum PolicyRuleCriteria {
  Attribute = 'attribute',
  Label = 'label'
}

export const LABEL_FILTER_ALL_ENTITIES = 'All Entities'

export enum NOTIFICATION_MODULE {
  ALERT = 'Alert',
  RULE_SET = 'Rule Set',
  DATASOURCE = 'Data Source',
  ROPA = 'ROPA',
  DSR = 'DSR',
  DSR_TICKET = 'DSR Ticket',
  PIA = 'PIA'
}

export const notificationModules = [
  NOTIFICATION_MODULE.ALERT,
  NOTIFICATION_MODULE.DATASOURCE,
  NOTIFICATION_MODULE.RULE_SET,
  NOTIFICATION_MODULE.ROPA,
  NOTIFICATION_MODULE.DSR,
  NOTIFICATION_MODULE.DSR_TICKET,
  NOTIFICATION_MODULE.PIA
]

export enum DSR_REQUEST_TYPES {
  ACCESS = 'ACCESS',
  ERASURE = 'ERASURE',
  RECTIFY = 'RECTIFY'
}

export enum API_DSR_REQUEST_TYPES {
  ACCESS = 'ACCESS',
  ERASURE = 'ERASURE',
  RECTIFICATION = 'RECTIFICATION',
  DO_NOT_SELL = 'DO_NOT_SELL',
  OTHER = 'OTHER'
}

export enum DSR_FILTER_DUE_DATE {
  PAST_DUE = 'pastDue',
  WITHIN_ONE_WEEK = 'withinOneWeek',
  WITHIN_ONE_MONTH = 'withinOneMonth'
}

export enum DSR_FILTER_CREATED_DATE {
  WITHIN_ONE_WEEK = 'thisWeek',
  WITHIN_ONE_MONTH = 'thisMonth'
}

export enum DSR_FILTER_MANUAL_ACTION {
  NEEDED = 'manualActionNeeded'
}

export const EMAIL_REGEX_RFC2822 = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/
export const DOMAIN_REGEX = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/

export enum ROPA_REQUEST_STEPS {
  RESPONSES = 1,
  GENERATE_REPORT
}
export enum ROPA_REQUEST_PROCESS_DEFINITION_TYPES {
  PROCESS_ACTIVITY = 1,
  PROCESS_GROUP,
  COMPANY_ROPA
}
export const ROPA_REQUEST_PROCESS_DEFINITION_TYPE_OPTIONS = [
  ROPA_REQUEST_PROCESS_DEFINITION_TYPES.PROCESS_ACTIVITY,
  ROPA_REQUEST_PROCESS_DEFINITION_TYPES.PROCESS_GROUP,
  ROPA_REQUEST_PROCESS_DEFINITION_TYPES.COMPANY_ROPA
]

export enum RENTENTION_OPTIONS {
  day = 'DAY',
  weekly = 'WEEKLY',
  monthly = 'MONTHLY'
}

export const CRON_SCAN_DEFAULT = '0 0 * * *'
export const CRON_SCAN_DEFAULT_SERVICE_NOW = '*/5 * * * *'

export const MAX_INSTANCES_LIST_PER_PAGE = 5
export const DEFAULT_GENERIC_DS_PROJECT = 'LIGHTBEAM-DEFAULT-PROJECT'

// databases
export enum DatabaseScanTypes {
  live = 'live',
  offline = 'offline'
}

export enum ProcessPurposeTypes {
  alumniOutreach = 'Alumni Outreach',
  contactProcessing = 'Contract Processing',
  customerProcessing = 'Customer onboarding',
  customerOrders = 'Customer orders',
  support = 'Support',
  emailManagement = 'Email management',
  hrOnboarding = 'HR Onboarding',
  marketEvents = 'Marketing event activities',
  payrollManagement = 'Payroll Management',
  leadGeneration = 'Sales lead generation',
  softwareDevelopment = 'Software development',
  staffAdministration = 'Staff administration',
  custom = 'Custom'
}

export enum AttributeInternalNames {
  firstName = 'FIRST_NAME',
  lastName = 'LAST_NAME',
  emailAddress = 'EMAIL_ADDRESS'
}

export const MESSAGES = {
  en: {
    ...en,
    ...i18n.messages.en
  },
  fr: {
    ...fr,
    ...i18n.messages.fr
  },
  es: {
    ...es,
    ...i18n.messages.es
  }
}

export const DEFAULT_LANGUAGE = 'en'
export const DSR_FORM_SUPPORTED_LANGUAGES = Object.keys(MESSAGES).map((key) => ({
  code: key,
  isDefault: key === DEFAULT_LANGUAGE
}))
export const DSR_FORMS_DEFAULT_LANGUAGES = [
  {
    code: DEFAULT_LANGUAGE,
    isDefault: true,
    i18Labels: translationsMapper(
      [
        {
          code: DEFAULT_LANGUAGE,
          isDefault: true
        }
      ],
      `language.${DEFAULT_LANGUAGE}`
    )
  }
]
export const DSR_SYSTEM_AUTOMATION_ASSIGNEE = 'system@lightbeam.ai'

export enum DownloadReportFormats {
  csv = 'CSV',
  json = 'JSON',
  pdf = 'PDF'
}
export enum DownloadReportTypes {
  fileList = 'FILE_LIST',
  tableList = 'TABLE_LIST',
  dsrRequestList = 'DSR_REQUEST_LIST',
  entityList = 'ENTITY_LIST',
  ropaProcessesList = 'ROPA_PROCESS_LIST',
  piaProcessesList = 'PIA_PROCESS_LIST',
  retentionViolation = 'RETENTION_VIOLATION',
  cookieConsentScanHistory = 'COOKIE_CONSENT_SCAN_HISTORY',
  docAnalyticsObjects = 'DOC_ANALYTICS_OBJECTS',
  accessControlUsers = 'ACCESS_CONTROL_USERS',
  accessControlGroups = 'ACCESS_CONTROL_GROUPS',
  accessControlObjects = 'ACCESS_CONTROL_OBJECTS',
  consentManagementLogs = 'CONSENT_MANAGEMENT_CONSENT_LOGS',
  dataSourceSummaryPdfReport = 'DATASOURCE_SUMMARY_PDF_REPORT',
  ropaPdfReport = 'ROPA_PDF_REPORT',
  piaPdfReport = 'PIA_PDF_REPORT'
}
export enum DownloadReportParameters {
  dsrRequestId = 'DSR_IDS',
  dsrRquestType = 'REQUEST_TYPE',
  dsrDataSubjectNameEmailId = `dataSubjectNameOrEmail`,
  dsrRequesStatus = 'STATUS',
  dsrassigneeEmail = 'ASSIGNEE_EMAIL',
  dsrDueDate = 'DUE_DATE',
  dsrCreatedAt = 'CREATED_AT'
}

export const dsrFormDefaultAttributesTranslationMapper = {
  [AttributeInternalNames.emailAddress]: 'dsr.configurator.section.dataSubject.email',
  [AttributeInternalNames.firstName]: 'dsr.configurator.section.dataSubject.firstName',
  [AttributeInternalNames.lastName]: 'dsr.configurator.section.dataSubject.lastName',
  [DsrFormElementTypes.requestTypes]: 'dsr.configurator.REQUEST_TYPES'
}

export const dsrFormDefaultAttributeNames = [
  AttributeInternalNames.firstName,
  AttributeInternalNames.lastName,
  AttributeInternalNames.emailAddress,
  DsrFormElementTypes.requestTypes
]

export enum LAST_MODIFIED_TIME {
  withinThreeMonths = 'WITHIN_THREE_MONTH',
  withinSixMonths = 'WITHIN_SIX_MONTH',
  withinOneYear = 'WITHIN_1_YEAR',
  threeMonths = 'THREE_MONTHS',
  sixMonths = 'SIX_MONTHS',
  oneYear = 'ONE_YEAR',
  threeYears = 'THREE_YEARS',
  fiveYears = 'FIVE_YEARS',
  sevenYears = 'SEVEN_YEARS',
  tenYears = 'TEN_YEARS'
}

export enum LEGAL_HOLD_TIME_PERIOD {
  thirtyDays = '30 Days',
  ninetyDays = '90 Days',
  sixMonths = '6 Months',
  oneYear = '1 Year',
  twoYears = '2 Years'
}

export enum RopaQuestionCategory {
  custom = 'CUSTOM',
  builtin = 'BUILT-IN'
}

export enum RopaQuestionnaireTopicNames {
  processDetails = 'processDetails',
  dataElements = 'dataElements',
  dataSubjects = 'dataSubjects',
  dataRetention = 'dataRetention',
  safeguards = 'safeguards',
  transfers = 'transfers',
  dataFlow = 'dataFlow',
  customTopics = 'customTopics'
}
export const MAX_FILE_SIZE_ACCEPTED = 5000000 // 5MB converted to bytes.
export enum ProcessReviewStatus {
  reviewDue = 'Review Due',
  reviewPastDue = 'Review Past Due',
  noReviewNeeded = 'No Review Needed'
}

export enum NOTIFICATION_TYPES {
  ropa = 'ROPA',
  dsr = 'DSR',
  dataSource = 'DATASOURCE',
  alert = 'ALERT',
  ruleSet = 'RULE_SET',
  dsrTicket = 'DSR_TICKET',
  pia = 'PIA'
}

export enum PrivacyFeatureType {
  ropa = 'ROPA',
  pia = 'PIA'
}

export enum PiaProcessFilter {
  reviewPending = 'Review Pending',
  reviewed = 'Reviewed',
  notSubmitted = 'Not submitted for Review',
  inProgress = 'Review in Progress'
}

export enum SMTP_CONFIG_TYPE {
  basic = 'Basic Auth',
  oAuth = 'OAuth'
}

export enum SMTP_PROVIDERS {
  google = 'Google',
  microsoft = 'Microsoft'
}

export const FEATURE_FLAGS = {
  INBOX_SCANNING: 'outlookProcessInboxMail',
  DOC_ANALYTICS: 'docAnalytics',
  CLOUD_DISCOVERY: 'isCloudDiscoveryEnabled'
}

export enum SkippedFileReason {
  downloadError = 'DOWNLOAD_ERROR',
  processingError = 'PROCESSING_ERROR',
  passwordProtected = 'PASSWORD_PROTECTED'
}

export enum SkippedFileReasonTextMapper {
  'Download Error' = SkippedFileReason.downloadError,
  'Processing Error' = SkippedFileReason.processingError,
  'Password Protected' = SkippedFileReason.passwordProtected
}

export enum ProcessSortValues {
  name = 'Name',
  updatedAt = 'UPDATED_AT',
  createdAt = 'CREATED_AT'
}

export enum ProcessFilterKeys {
  owner = 'OWNER',
  ownerOrReviewer = 'PROCESS_OWNER_OR_REVIEWER',
  attributeIds = 'ATTRIBUTE_IDS',
  attributeSetIds = 'ATTRIBUTE_SET_IDS',
  role = 'ROLE',
  risk = 'RISK',
  assessmentReview = 'ASSESSMENT_REVIEW',
  dataSourceIds = 'DATASOURCE_IDS',
  reviewFrequency = 'REVIEW_FREQUENCY_DURATION',
  reviewStatus = 'REVIEW_STATUS',
  templateIds = 'TEMPLATE_IDS',
  processGroupIds = 'PROCESS_GROUP_IDS',
  processType = 'PROCESS_TYPE'
}

export enum BulkReclassifyOptions {
  markAsSensitive = 'markAsSensitive',
  markAsNotSensitive = 'markAsNotSensitive'
}

export const SHAREPOINT_DASHBOARD_TABS = {
  SUMMARY: 'summary',
  LENS: 'lens'
}

export const SHAREPOINT_FILES_TABS = {
  CLASSIFICATION: 'classification',
  LENS: 'lens'
}

export const TAB_VARIANT: { LINE_TABS: 'line'; BUTTON_TABS: 'button'; PRIMARY_TABS: 'primary' } = {
  LINE_TABS: 'line',
  BUTTON_TABS: 'button',
  PRIMARY_TABS: 'primary'
}

export const BUTTON_VARIANT: {
  SUCCESS: 'success'
  ERROR: 'error'
  LINK: 'link'
  PRIMARY: 'primary'
  SECONDARY: 'secondary'
} = {
  SUCCESS: 'success',
  ERROR: 'error',
  LINK: 'link',
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

export enum DOC_ANALYTICS_LAST_UPDATED_TIME {
  ZERO_TO_THREE_MONTHS = '0_3_MONTHS',
  ZERO_TO_SIX_MONTHS = '0_6_MONTHS',
  ZERO_TO_ONE_YEAR = '0_1_YEARS',
  GREATER_THAN_THREE_MONTHS = 'GT_3_MONTHS',
  GREATER_THAN_SIX_MONTHS = 'GT_6_MONTHS',
  GREATER_THAN_ONE_YEAR = 'GT_1_YEARS',
  GREATER_THAN_THREE_YEARS = 'GT_3_YEARS',
  GREATER_THAN_FIVE_YEARS = 'GT_5_YEARS',
  GREATER_THAN_SEVEN_YEARS = 'GT_7_YEARS'
}

export enum DOC_ANALYTICS_FILE_SIZE {
  LT_16KB = 'LT_16KB',
  GTE_16KB_LT_128KB = 'GTE_16KB_LT_128KB',
  GTE_128KB_LT_1MB = 'GTE_128KB_LT_1MB',
  GTE_1MB_LT_128MB = 'GTE_1MB_LT_128MB',
  GTE_128MB_LT_1GB = 'GTE_128MB_LT_1GB',
  GTE_1GB = 'GTE_1GB'
}

export const FILES_TAB_WIDGETS = {
  ALL_FILES: 'allFiles',
  SKIPPED_FILES: 'skippedFiles'
}

export const SCAN_CONDITIONS_DS_SUPPORT_LIST = [
  {
    dataSourceType: DATA_SOURCE_TYPES.sharePoint,
    objectType: 'site',
    objectTypeForAPI: {
      first: ScanConditionsObjectTypes.site
    }
  },
  {
    dataSourceType: DATA_SOURCE_TYPES.aws,
    objectType: 'bucket',
    objectTypeForAPI: {
      first: ScanConditionsObjectTypes.bucketId
    }
  },
  {
    dataSourceType: DATA_SOURCE_TYPES.googleCloudStorage,
    objectType: ScanConditionsObjectTypes.bucketId,
    objectTypeForAPI: {
      first: ScanConditionsObjectTypes.bucketId
    }
  },
  {
    dataSourceType: DATA_SOURCE_TYPES.gdrive,
    objectType: 'drive',
    objectTypeForAPI: {
      first: ScanConditionsObjectTypes.drive,
      second: ScanConditionsObjectTypes.sharedDrive
    }
  },
  {
    dataSourceType: DATA_SOURCE_TYPES.oneDrive,
    objectType: 'drive',
    objectTypeForAPI: {
      first: ScanConditionsObjectTypes.drive
    }
  },
  {
    dataSourceType: DATA_SOURCE_TYPES.gmail,
    objectType: 'mail',
    objectTypeForAPI: {
      first: ScanConditionsObjectTypes.mailBox,
      second: ScanConditionsObjectTypes.mailGroup
    }
  },
  {
    dataSourceType: DATA_SOURCE_TYPES.outLook,
    objectType: 'mail',
    objectTypeForAPI: {
      first: ScanConditionsObjectTypes.mailBox,
      second: ScanConditionsObjectTypes.mailGroup
    }
  }
]

export const SCAN_CONDITIONS_SUPPORTED_DATASOURCES = SCAN_CONDITIONS_DS_SUPPORT_LIST.map(
  (ds) => ds.dataSourceType
)

export const ARCHIVAL_SUPPORTED_DATASOURCES = [
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.smb
]

export const PAUSE_RESUME_SUPPORTED_DATASOURCES = [
  DATA_SOURCE_TYPES.gdrive,
  DATA_SOURCE_TYPES.gdrivePersonal,
  DATA_SOURCE_TYPES.gmail,
  DATA_SOURCE_TYPES.aws,
  DATA_SOURCE_TYPES.googleCloudStorage,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.outLook,
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.teams,
  DATA_SOURCE_TYPES.smb
]
export enum CronScheduleTypes {
  humanReadable = 'HUMAN_READABLE_SCHEDULE',
  expression = 'CRON_EXPRESSION'
}

export const RESCAN_SUPPORTED_DATASOURCES = [
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.aws,
  DATA_SOURCE_TYPES.azureBlob,
  DATA_SOURCE_TYPES.dropbox,
  DATA_SOURCE_TYPES.gdrive,
  DATA_SOURCE_TYPES.smb
]
export const SUPPORTED_TYPES_FOR_ANNOTATION_DOWNLOAD = [
  ATTCHEMENT_TYPE.PNG,
  ATTCHEMENT_TYPE.JPEG,
  ATTCHEMENT_TYPE.PDF
]
export const OBJECT_REVOKE_ACCESS_SUPPORTED_DATASOURCES = [
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.gdrive,
  DATA_SOURCE_TYPES.smb
]

export const OBJECT_ARCHIVE_SUPPORTED_DATASOURCES = [
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.smb
]

export const OBJECT_DELETE_SUPPORTED_DATASOURCES = [
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.postgreSql,
  DATA_SOURCE_TYPES.outLook,
  DATA_SOURCE_TYPES.smb,
  DATA_SOURCE_TYPES.hubspot
]

export const GOVERNANCE_SUPPORTED_DATASOURCES = [
  DATA_SOURCE_TYPES.sharePoint,
  DATA_SOURCE_TYPES.oneDrive,
  DATA_SOURCE_TYPES.gdrive,
  DATA_SOURCE_TYPES.smb
]

export enum CONSENT_TYPES {
  subscribed = 'SUBSCRIBED',
  unsubscribed = 'UNSUBSCRIBED'
}

export const isDocClassificationEnabled = () =>
  localStorageService.getItem(LocalStorageKeys.IsDocClassificationEnabled.key) || false

export const DASHBOARD_WIDGET_OTHERS_KEY = 'Others'
export const DEFAULT_WIDGET_OTHERS_COLOR = '#CCCCCC'

export const FILES_GROUP_BY = {
  LABELS: 'labels',
  CLASSIFICATION: 'classification'
}

export const WIDGET_VARIANT = {
  DROPDOWN: 'dropdown',
  TABS: 'tabs'
}

export const DSR_BULK_DOWNLOAD_TYPES = {
  allReports: 'BULK_DOWNLOAD_ALL_REPORTS',
  selectedReports: 'BULK_DOWNLOAD_REPORT'
}

export const DISABLE = 'DISABLE'
