import { URL_ATTRIBUTES, URL_INSIGHTS, URL_SETTINGS } from '../../../constants'

export const BASE_PATH = URL_INSIGHTS + URL_ATTRIBUTES + URL_SETTINGS

export enum DetectionType {
  Pattern = 'Pattern',
  KeyValue = 'KeyValue'
}

export const DetectionTypeOptions = [
  {
    value: DetectionType.KeyValue,
    text: 'attributeManagementSettings.customAttributes.detection.keyValue',
    description: 'attributeManagementSettings.customAttributes.detection.keyValue.description'
  },
  {
    value: DetectionType.Pattern,
    text: 'attributeManagementSettings.customAttributes.detection.pattern',
    description: 'attributeManagementSettings.customAttributes.detection.pattern.description'
  }
]

export enum RegexType {
  Key = 'KEY',
  Medium = 'MEDIUM',
  High = 'HIGH'
}
